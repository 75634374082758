import { ModalService, StepsService, IssuesService, isDefined, IssueLevel } from '@icc/helpers';

import {
    ConfigurationsService,
    Common,
    AppConfigFactory,
    APP_CONFIG,
    ParametersService,
    ConfiguratorsDataService,
    core,
    StateService,
    EventBusService,
    TranslateService,
    WindowActiveConfiguration,
} from '@icc/common';
import { Inject, Injectable } from '@angular/core';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService, PriceRollerService } from '@icc/price';
import { MontagesInfoPageComponent } from 'libs/configurator/shutter/src/lib/montages-info-page/montages-info-page.component';
import { GuidesPageComponent } from 'libs/configurator/shutter/src/lib/guides-page/guides-page.component';
import { SlatsPageComponent } from 'libs/configurator/shutter/src/lib/slats-page/slats-page.component';
import { Profile } from 'libs/window/src/lib/types/Profile';
import { ProfilesListPageComponent } from 'libs/configurator/window/src/lib/profiles/profiles-list-page/profiles-list-page.component';
import { RollerShutterActiveConfiguration } from '@icc/common/configurations/RollerShutterActiveConfiguration';
import { ColorsService } from './colors.service';
import { AccessoriesService } from '../window/accessories/accessories.service';

@Injectable()
export class RollerDimensionsService {
    private allProfiles = [];
    private allRoundReels = [];
    private matchedPrices = [];

    railCutAngles = [];
    private profilesForSystem = [];
    private profiles = [];
    roundReels = [];
    boxSizes = [];
    prices = [];
    pricesData = [];
    montagesInfo = {};
    defaultRealBoxHeight = 0;
    loadedData = false;
    guideProfiles = [];
    slatProfiles = [];
    updateDimensionsCounter = 0;
    dimensionsValid: {
        width: boolean,
        height: boolean
    } = {
        width: true,
        height: true
    };
    constructor(
        private modalService: ModalService,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind' | 'window'>,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private parametersService: ParametersService,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private priceService: PriceService,
        private priceRollerService: PriceRollerService,
        private issuesService: IssuesService,
        private eventBusService: EventBusService,
        private stateService: StateService,
        private stepsService: StepsService,
        private colorsService: ColorsService,
        private accessoriesService: AccessoriesService,
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribeWithoutConfiguration('changedStep', () => {
            this.updateDimensions();
        });

        this.eventBusService.subscribe('setShutterColor', data => {
            this.loadBoxHeights(
                false,
                false,
                data.activeConfiguration as RollerShutterActiveConfiguration
            );
            this.setBoundaryDimensionsFromPrices(
                data.activeConfiguration as RollerShutterActiveConfiguration
            );
            this.loadProfilesBySystem(data.activeConfiguration as RollerShutterActiveConfiguration);
        });
    }

    /**
     * Funkcja inicjalizujaca
     */
    init() {
        if (
            this.currentConfiguratorService.conf !== 'window'
            && this.currentConfiguratorService.conf !== 'roller_shutter'
            && this.currentConfiguratorService.conf !== 'external_blind'
            && this.currentConfiguratorService.conf !== 'hs'
            && this.currentConfiguratorService.conf !== 'sliding_door'
            && this.currentConfiguratorService.conf !== 'folding_door'
        ) {
            return;
        }
        this.updateDimensionsCounter = 0;
        if (Common.isArray(this.configuratorsDataService.data.rollerShutterProfiles)) {
            this.allProfiles = this.configuratorsDataService.data.rollerShutterProfiles;
        }
        if (Common.isArray(this.configuratorsDataService.data.roundReels)) {
            this.allRoundReels = this.configuratorsDataService.data.roundReels;
        }
        if (Common.isArray(this.configuratorsDataService.data.rollerPrices)) {
            this.prices = this.configuratorsDataService.data.rollerPrices;
        }
        if (Common.isArray(this.configuratorsDataService.data.rollerPricesData)) {
            this.pricesData = this.configuratorsDataService.data.rollerPricesData;
        }
        if (Common.isObject(this.configuratorsDataService.data.rollerShutterMontages)) {
            this.montagesInfo = this.configuratorsDataService.data.rollerShutterMontages;
        }
        this.railCutAngles = this.config().IccConfig.Settings.railCutAngles.split(',');
        this.loadProfilesBySystem(this.configurationsService.conf.Current);
        this.setSlatProfiles();
        if (!this.configurationsService.conf.Current.RollerShutter.profile?.id) {
            this.setDefaultData();
        } else {
            this.setBoundaryDimensionsFromPrices();
        }
        this.updateRailsBottomCut(false);

        this.priceService.count();
        this.parametersService.count(this.configurationsService.conf.Current);
        this.loadedData = true;
    }

    /**
     * Funkcja ustawiajaca domyślne dane
     */
    setDefaultData() {
        this.loadBoxHeights();
        this.setBoundaryDimensionsFromPrices();
        this.configurationsService.conf.Current.RollerShutter.boxType = 'R';
        this.setDefaultProfile(this.configurationsService.conf.Current);
        this.configurationsService.conf.Current.RollerShutter.changedSize = false;
        this.updateDimensions();
    }

    /**
     * Funkcja pobierajace prowadnice oraz ustawiajaca domyślna prowadnice
     */
    setGuideProfiles(conf = this.configurationsService.conf.Current) {
        if (
            !this.config().IccConfig.Configurators.roller_shutter.guides
            || !(
                this.configuratorsDataService.data
                && this.configuratorsDataService.data.profilesProfiles
            )
            || !conf.RollerShutter.profile
        ) {
            return;
        }

        const matchedProfiles = this.configuratorsDataService.data.profilesProfiles
            .filter(
                p =>
                    (p.profile_id === conf.RollerShutter.profile.id
                        || p.connected_profile_id === conf.RollerShutter.profile.id)
                    && p.type === 'assembling'
            )
            .map(p => {
                if (p.profile_id === conf.RollerShutter.profile.id) {
                    return p.connected_profile_id;
                } else if (p.connected_profile_id === conf.RollerShutter.profile.id) {
                    return p.profile_id;
                }
            });

        this.guideProfiles = this.configuratorsDataService.data.rollerShutterProfiles.filter(
            p =>
                matchedProfiles.includes('' + p.id)
                && p.type === 'roller_guide'
                && p.systems.indexOf(conf.RollerShutter.system.id) > -1
        );

        const guideProfiles = this.guideProfiles.filter(p => !p.options.includes('double_guide'));
        const commonGuideProfiles = this.guideProfiles.filter(p =>
            p.options.includes('double_guide')
        );
        const shutters = conf.RollerShutter.shutters.slice();
        const pauseId = this.eventBusService.pause(['saveGuideProfile']);
        try {
            if (this.guideProfiles) {
                if (shutters.length) {
                    let defaultGuideProfile = null;
                    if (guideProfiles.length) {
                        defaultGuideProfile = guideProfiles[0];
                    }
                    const leftGuide = this.getGuide(shutters[0].id, 'left', conf);
                    if (!leftGuide || !this.guideProfiles.some(g => g.id === leftGuide.id)) {
                        this.saveGuideProfile(shutters[0].id, defaultGuideProfile, 'left', true, conf);
                    }
                    const rightGuide = this.getGuide(shutters[shutters.length - 1].id, 'right', conf)
                    if (!rightGuide || !this.guideProfiles.some(g => g.id === rightGuide.id)) {
                        this.saveGuideProfile(
                            shutters[shutters.length - 1].id,
                            defaultGuideProfile,
                            'right',
                            true,
                            conf
                        );
                    }
                    shutters.splice(0, 1);

                    if (shutters.length) {
                        for (let i = 0; i < shutters.length; i++) {
                            const shutter = shutters[i];
                            if (!('commonRail' in shutter) || !shutter.commonRail) {
                                const middleLeftGuide = this.getGuide(shutter.id, `middleLeft${shutter.id}`, conf);
                                if (!middleLeftGuide || !this.guideProfiles.some(g => g.id === middleLeftGuide.id)) {
                                    this.saveGuideProfile(
                                        shutter.id - 1,
                                        defaultGuideProfile,
                                        `middleLeft${shutter.id}`,
                                        true,
                                        conf
                                    );
                                }
                                const middleRightGuide = this.getGuide(shutter.id, `middleRight${shutter.id}`, conf);
                                if (!middleRightGuide || !this.guideProfiles.some(g => g.id === middleRightGuide.id)) {
                                    this.saveGuideProfile(
                                        shutter.id,
                                        defaultGuideProfile,
                                        `middleRight${shutter.id}`,
                                        true,
                                        conf
                                    );
                                }
                            } else {
                                if (commonGuideProfiles.length) {
                                    defaultGuideProfile = commonGuideProfiles[0];
                                } else {
                                    defaultGuideProfile = null;
                                }
                                const commonGuide = this.getGuide(shutter.id, `common${shutter.id}`, conf);
                                if (!commonGuide || !this.guideProfiles.some(g => g.id === commonGuide.id)) {
                                    this.saveGuideProfile(
                                        shutter.id,
                                        defaultGuideProfile,
                                        `common${shutter.id}`,
                                        true,
                                        conf
                                    );
                                }/*  else {
                                    this.saveGuideProfile(
                                        shutter.id,
                                        null,
                                        `common${shutter.id}`,
                                        true,
                                        conf
                                    );
                                } */
                            }
                        }
                    }
                }
            } else {
                this.resetGuides(conf);
            }
        } finally {
            this.eventBusService.resume(['saveGuideProfile'], pauseId);
        }
    }

    /**
     * Funkcja zapisujaca wybrana prowadnice
     */
    saveGuideProfile(
        rollerId,
        profile,
        side,
        selectedAsDefault = false,
        conf = this.configurationsService.conf.Current
    ) {
        const sideNumber = side.match(/[0-9]+/) || '';

        const common = side.includes('common' + sideNumber);
        const leftOf = side.includes('middleLeft' + sideNumber);
        const rightOf = side.includes('middleRight' + sideNumber);

        if (conf.RollerShutter.guideRails.length) {
            const existsGuideRailIndex = conf.RollerShutter.guideRails.findIndex(
                g => g.side === side && g.rollerId === rollerId
            );
            if (existsGuideRailIndex > -1) {
                conf.RollerShutter.guideRails.splice(existsGuideRailIndex, 1);
            }

            if (common) {
                while (
                    conf.RollerShutter.guideRails.findIndex(g =>
                        g.side.includes('middleLeft' + sideNumber)
                    ) > -1
                ) {
                    const middleLeftGuideRailIndex = conf.RollerShutter.guideRails.findIndex(g =>
                        g.side.includes('middleLeft' + sideNumber)
                    );
                    if (middleLeftGuideRailIndex > -1) {
                        conf.RollerShutter.guideRails.splice(middleLeftGuideRailIndex, 1);
                    }
                }

                while (
                    conf.RollerShutter.guideRails.findIndex(g =>
                        g.side.includes('middleRight' + sideNumber)
                    ) > -1
                ) {
                    const middleRightGuideRailIndex = conf.RollerShutter.guideRails.findIndex(g =>
                        g.side.includes('middleRight' + sideNumber)
                    );
                    if (middleRightGuideRailIndex > -1) {
                        conf.RollerShutter.guideRails.splice(middleRightGuideRailIndex, 1);
                    }
                }
            }

            if (leftOf || rightOf) {
                while (
                    conf.RollerShutter.guideRails.findIndex(g =>
                        g.side.includes('common' + sideNumber)
                    ) > -1
                ) {
                    const commonGuideRailIndex = conf.RollerShutter.guideRails.findIndex(g =>
                        g.side.includes('common' + sideNumber)
                    );
                    if (commonGuideRailIndex > -1) {
                        conf.RollerShutter.guideRails.splice(commonGuideRailIndex, 1);
                    }
                }
            }
        }

        const guideRail = {
            id: profile ? profile.id : null,
            name: profile ? profile.name : null,
            leftOf: null,
            rightOf: null,
            side,
            selectedAsDefault,
            rollerId,
        };

        switch (side) {
            case 'left':
                guideRail.leftOf = rollerId;
                break;
            case 'right':
                guideRail.rightOf = rollerId;
                break;
        }

        if (side.includes('common')) {
            guideRail.rightOf = rollerId - 1;
            guideRail.leftOf = rollerId;
        }

        if (side.includes('middleLeft')) {
            guideRail.rightOf = rollerId;
        }

        if (side.includes('middleRight')) {
            guideRail.leftOf = rollerId;
        }

        conf.RollerShutter.guideRails.push(guideRail);
        this.eventBusService.post({ key: 'saveGuideProfile', value: null });
        this.priceService.count();
    }

    /**
     * Funkcja pobierajaca wybrana prowadnice
     */
    getGuide(rollerId, side, conf = this.configurationsService.conf.Current) {
        return conf.RollerShutter.guideRails.find(g => g[`${side}Of`] === rollerId || g.side?.includes(side));
    }

    /**
     * Funkcja sprawdzajaca czy istnieje prowadnica
     */
    existsGuideProfile(id) {
        return this.guideProfiles.find(p => p.id === id) || false;
    }

    /**
     * Funkcja resetujaca prowadnice
     */
    resetGuides(conf = this.configurationsService.conf.Current) {
        conf.RollerShutter.guideRails = [];
    }

    /**
     * Funkcja pobierajace prowadnice oraz ustawiajaca domyślna prowadnice
     */
    setSlatProfiles(conf = this.configurationsService.conf.Current) {
        if (
            !this.config().IccConfig.Configurators.roller_shutter.slats
            || !(
                this.configuratorsDataService.data
                && this.configuratorsDataService.data.profilesProfiles
            )
            || !conf.RollerShutter.profile
        ) {
            return;
        }

        const matchedProfiles = this.configuratorsDataService.data.profilesProfiles
            .filter(
                p =>
                    (p.profile_id === conf.RollerShutter.profile.id
                        || p.connected_profile_id === conf.RollerShutter.profile.id)
                    && p.type === 'assembling'
            )
            .map(p => {
                if (p.profile_id === conf.RollerShutter.profile.id) {
                    return p.connected_profile_id;
                } else if (p.connected_profile_id === conf.RollerShutter.profile.id) {
                    return p.profile_id;
                }
            });

        this.slatProfiles = this.configuratorsDataService.data.rollerShutterProfiles.filter(
            p => matchedProfiles.includes('' + p.id) && p.type === 'roller_slat'
        );
        const pauseId = this.eventBusService.pause(['saveSlatProfile']);
        try {
            if (this.slatProfiles.length) {
                const defaultSlatProfile = this.slatProfiles[0];
                conf.RollerShutter.shutters.forEach(shutter => {
                    const slat = this.getSlat(shutter.id, conf);
                    if (!slat || !this.slatProfiles.some(s => s.id === slat.id)) {
                        this.saveSlatProfile(shutter.id, defaultSlatProfile, true, conf);
                    }
                });
            } else {
                this.resetSlats(conf);
            }
        } finally {
            this.eventBusService.resume(['saveSlatProfile'], pauseId);
        }
    }

    /**
     * Funkcja zapisujaca wybrana prowadnice
     */
    saveSlatProfile(
        rollerId,
        profile,
        selectedAsDefault = false,
        conf = this.configurationsService.conf.Current
    ) {
        if (!('id' in profile) || !('name' in profile)) {
            return;
        }

        if (conf.RollerShutter.slats.length) {
            const existsSlatIndex = conf.RollerShutter.slats.findIndex(
                g => g.rollerId === rollerId
            );
            if (existsSlatIndex > -1) {
                conf.RollerShutter.slats.splice(existsSlatIndex, 1);
            }
        }

        const slat = {
            id: profile.id,
            name: profile.name,
            selectedAsDefault,
            rollerId,
        };

        conf.RollerShutter.slats.push(slat);
        this.eventBusService.post({ key: 'saveSlatProfile', value: null });
        this.priceService.count();
    }

    /**
     * Funkcja pobierajaca wybrana prowadnice
     */
    getSlat(rollerId, conf = this.configurationsService.conf.Current) {
        return conf.RollerShutter.slats.find(g => g.rollerId === rollerId);
    }

    /**
     * Funkcja sprawdzajaca czy istnieje listwa dolna
     */
    existsSlatProfile(id) {
        return this.slatProfiles.find(p => p.id === id) || false;
    }

    /**
     * Funkcja resetujaca listwy dolne
     */
    resetSlats(conf = this.configurationsService.conf.Current) {
        conf.RollerShutter.slats = [];
    }

    /**
     * Ustawia graniczne wymiary rolety
     */
    setBoundaryDimensionsFromPrices(conf = this.configurationsService.conf.Current) {
        this.matchedPrices = this.priceRollerService.getPricesForRoller(
            conf.RollerShutter,
            this.configuratorsDataService.data.rollerPricesData,
            conf.type,
            WindowActiveConfiguration.is(conf) && conf.Colors
        );
        if (Common.isDefined(this.matchedPrices) && Common.isDefined(this.matchedPrices[0])) {
            conf.RollerShutter.hasPrices = true;
        } else {
            conf.RollerShutter.hasPrices = false;
        }
        if(this.config().IccConfig.Configurators.roller_shutter.configuratorDimensionalRestriction) {
            this.loadBoxHeights(undefined, true, conf);
        }
        this.priceService.count();
        this.parametersService.count(conf);
    }

    /**
     * Zmiana kąta zacięcia prowadnicy
     */
    updateRailsBottomCut(updateShutterHeight = false) {
        const angle =
            Number(this.configurationsService.conf.Current.RollerShutter.railsBottomCut) || 0;

        if (updateShutterHeight) {
            this.configurationsService.conf.Current.RollerShutter.shutters.map(shutter => {
                const guide = this.getGuide(shutter.id, 'left');
                const guideProfile = this.guideProfiles.find(el => el.id === guide.id);
                const height =
                    Number((guideProfile.depth * Math.tan(angle * (Math.PI / 180))).toFixed(1))
                    || 0;
                shutter.realHeight -= shutter.railCutHeight || 0;
                shutter.realHeight += height;
                shutter.railCutHeight = height;
            });
        }

        this.updateDimensions();
    }

    /**
     * Funkcja odświeżajaca wysokość
     */
    // eslint-disable-next-line max-statements
    updateDimensions(
        changeHeight = false,
        changedInputId?: number,
        conf = this.configurationsService.conf.Current,
        showIssues = true
    ) {
        if (!conf?.RollerShutter?.shutters?.length) {
            return;
        }
        showIssues = this.config().IccConfig.Configurators.roller_shutter.configuratorDimensionalRestriction ? showIssues : true;
        const commonRail =
            (conf.type === 'roller_shutter' || conf.type === 'external_blind'
                ? this.config().IccConfig.Configurators.price.commonRail.rollerShutter
                : this.config().IccConfig.Configurators.price.commonRail.window) || false;
        let i, shutter;
        let maxHeight = 0;
        let totalWidth = 0;
        const shutterWithCommonRail = [];
        let shutterWidth = 0;
        let shutterHeight = 0;
        let correctDimensions = {
            valid: true,
            messages: [],
        };

        const railsHeightModify = conf.RollerShutter.railsHeightModify;
        const matchedPrices = this.priceRollerService.getPricesForRoller(
            conf.RollerShutter,
            this.configuratorsDataService.data.rollerPricesData,
            conf.type,
            WindowActiveConfiguration.is(conf) && conf.Colors,
            false
        );

        if (
            changedInputId !== undefined &&
            changeHeight &&
            conf.RollerShutter?.type?.measureType === 'with_box'
        ) {
            conf.RollerShutter.shutters = conf.RollerShutter.shutters.map((item, index) => {
                if (index !== changedInputId) {
                    item.realHeight += conf.RollerShutter.realBoxHeight;
                }
                return item;
            });
        }

        const boxHeightForLongestShutter = this.getLongestHeightForShutterAndBox();

        const shuttersCopy = core.copy(conf.RollerShutter.shutters);
        for (i = conf.RollerShutter.shutters.length - 1; i >= 0; i--) {
            shutter = conf.RollerShutter.shutters[i];
            const shutterCopy = shuttersCopy[i];
            if (
                (changeHeight ||
                    (this.updateDimensionsCounter === 0 &&
                        !this.configurationsService.conf.Edit)) &&
                conf.RollerShutter?.type?.measureType === 'with_box'
            ) {
                shutter.realHeight = shutterCopy.realHeight - boxHeightForLongestShutter;
                conf.RollerShutter.realBoxHeight = boxHeightForLongestShutter;
            }
            if (conf.type !== 'roller_shutter' && conf.type !== 'external_blind') {
                if (
                    railsHeightModify != null &&
                    this.config().IccConfig.Configurators.extendedRollerInWindow !== 'full'
                ) {
                    shutter.railsHeightModify = railsHeightModify;
                    shutter.realHeight = conf.Height + railsHeightModify;
                } else {
                    shutter.railsHeightModify = shutter.realHeight - conf.Height;
                }
            }

            if (shutter.commonRail) {
                shutterWithCommonRail.push(shutter);
            } else {
                // eslint-disable-next-line no-loop-func
                shutterWithCommonRail.forEach((sh) => {
                    sh.realHeight = shutter.realHeight;
                    if (conf.type !== 'roller_shutter' && conf.type !== 'external_blind') {
                        if (
                            railsHeightModify != null &&
                            this.config().IccConfig.Configurators.extendedRollerInWindow !==
                                'full'
                        ) {
                            sh.railsHeightModify = railsHeightModify;
                            sh.realHeight = conf.Height + railsHeightModify;
                        } else {
                            sh.railsHeightModify = sh.realHeight - conf.Height;
                        }
                    }
                });
                core.clear(shutterWithCommonRail);
            }

            if (this.config().IccConfig.Configurators.roller_shutter.extraDimensionsOptions) {
                if (shutter.realHeight > maxHeight) {
                    maxHeight = shutter.realHeight;
                }
                conf.RollerShutter.realRollerHeight = maxHeight;
            }

            totalWidth += ~~shutter.realWidth;

            if (commonRail) {
                if (this.config().IccConfig.Configurators.price.shutterHeightWithBox) {
                    shutterHeight = shutter.realHeight + conf.RollerShutter.realBoxHeight;
                } else {
                    shutterHeight = shutter.realHeight;
                }
                if (shutter.commonRail) {
                    shutterWidth += shutter.realWidth;
                } else {
                    shutterWidth += shutter.realWidth;
                    correctDimensions = this.validDimensions(
                        {
                            width: shutterWidth,
                            height: shutterHeight,
                            hasMosquito: shutter.mosquito,
                            matchedPrices,
                            index: i,
                        },
                        correctDimensions
                    );
                    shutterHeight = 0;
                    shutterWidth = 0;
                }
            }
        }
        conf.RollerShutter.realBoxWidth = totalWidth;

        if (!commonRail) {
            let height = 0;
            if (this.config().IccConfig.Configurators.price.shutterHeightWithBox) {
                height = conf.RollerShutter.realRollerHeight + conf.RollerShutter.realBoxHeight;
            } else {
                height = conf.RollerShutter.realRollerHeight;
            }

            correctDimensions = this.validDimensions(
                {
                    width: conf.RollerShutter.realBoxWidth,
                    height,
                    hasMosquito: false,
                    matchedPrices,
                    index: null,
                },
                correctDimensions
            );
        }
        if (
            this.currentConfiguratorService.conf === 'roller_shutter' ||
            this.currentConfiguratorService.conf === 'external_blind'
        ) {
            if (!correctDimensions.valid) {
                const message = correctDimensions.messages
                    .map(m => {
                        switch (m.type) {
                            case 'should-be-mosquito':
                                return this.translateService.instant(
                                    'ROLLERSHUTTER|Podane wymiary są nieprawidłowe. Włącz siatkę antyinsektową w części {index}.',
                                    { index: m.index }
                                );
                            case 'shouldnt-be-mosquito':
                                return this.translateService.instant(
                                    'ROLLERSHUTTER|Podane wymiary są nieprawidłowe. Wyłącz siatkę antyinsektową w części {index}.',
                                    { index: m.index }
                                );
                            case 'incorrect-dimension':
                                return this.translateService.instant(
                                    'CONFIGURATOR|Podane wymiary są nieprawidłowe.'
                                );
                        }
                    })
                    .filter((item, pos, self) => self.indexOf(item) === pos)
                    .join('<br><br>');
                if(showIssues) {
                    this.issuesService.simpleRegister(
                        'incorrect-roller_shutter-dimensions',
                        'Podane wymiary są nieprawidłowe.',
                        message,
                        this.configurationsService.conf.Current,
                        {
                            logLevel: IssueLevel.NONE
                        }
                    );
                }
            } else {
                this.issuesService.unregister(
                    'incorrect-roller_shutter-dimensions',
                    this.configurationsService.conf.Current
                );
            }
            this.configurationsService.conf.Current.Height = this.configurationsService.conf.Current.RollerShutter.realRollerHeight;
            this.configurationsService.conf.Current.Width = this.configurationsService.conf.Current.RollerShutter.realBoxWidth;
        }
        if (correctDimensions.valid) {
            this.refreshRatio();
            conf.RollerShutter.rollerHeight =
                conf.RollerShutter.realRollerHeight * conf.RollerShutter.ratio;
            conf.RollerShutter.boxWidth = 0;
            for (i = 0; i < conf.RollerShutter.shutters.length; i++) {
                shutter = conf.RollerShutter.shutters[i];
                shutter.width = shutter.realWidth * conf.RollerShutter.ratio;
                shutter.height = shutter.realHeight * conf.RollerShutter.ratio;
                conf.RollerShutter.boxWidth += shutter.width;
                if (i > 0) {
                    shutter.x =
                        conf.RollerShutter.shutters[i - 1].width
                        + conf.RollerShutter.shutters[i - 1].x;
                }
            }
            this.checkShuttersConstructionLimitations();
            this.loadRoundReelsByProfile(this.configurationsService.conf.Current);
            this.eventBusService.post({
                key: 'changedShutter',
                value: null,
            });
            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
            this.priceService.count();
            this.parametersService.count(this.configurationsService.conf.Current);
            this.updateDimensionsCounter++;
        }
    }
    getHeightWithShutterAndBox(height) {
        const filteredBoxHeights = this.loadBoxHeights(height);
        let poss = filteredBoxHeights.map((e) => height - e);
        poss = poss.filter(
            (e, index) =>
                e + filteredBoxHeights[index] === height &&
                this.isBoxOkForHeight(e, filteredBoxHeights[index])
        );
        return poss[0];
    }

    isBoxOkForHeight(height, boxHeight) {
        const filteredBoxHeights = this.loadBoxHeights(height);
        return filteredBoxHeights.includes(boxHeight);
    }

    getLongestHeightForShutterAndBox(conf = this.configurationsService.conf.Current): number {
        if (conf.RollerShutter?.type?.measureType !== 'with_box') {
            return 0;
        }

        let longestShutter = 0;

        if (conf.RollerShutter.shutters.some((e) => e?.commonRail)) {
            longestShutter = conf.RollerShutter.shutters
                .filter((e) => !e.commonRail)
                .map((item) => item.realHeight)
                .reduce((a, b) => Math.max(a, b));
        } else {
            longestShutter = conf.RollerShutter.shutters
                .map((item) => item.realHeight)
                .reduce((a, b) => Math.max(a, b));
        }
        return longestShutter - this.getHeightWithShutterAndBox(longestShutter);
    }

    /**
     * Funkcja odświezania wysokości skrzynki
     * @param  {object} height wysokość
     */
    // eslint-disable-next-line max-statements
    loadBoxHeights(height?, alwaysChange = false, conf = this.configurationsService.conf.Current) {
        const boxHeights = [];
        let filteredBoxHeights = [];
        const boxHeightsList = [];
        let rollerPriceData = [];
        let i = 0;
        let comparedHeight = conf.Height + conf.RollerShutter.railsHeightModify;
        const addBox = conf.RollerShutter?.type?.measureType === 'with_box';
        const comparedHeightWithBoxHeight =
            !height
            && this.config().IccConfig.Configurators.price.shutterHeightWithBox
            && conf.RollerShutter.realBoxHeight;
        if (height) {
            comparedHeight = height;
        }
        const rollerPricesFiltered = this.priceRollerService.getPricesForRoller(
            conf.RollerShutter,
            this.configuratorsDataService.data.rollerPricesData,
            conf.type,
            WindowActiveConfiguration.is(conf) && conf.Colors
        );
        const fieldBoxHeight =
            Common.isObject(conf.RollerShutter.drive) && conf.RollerShutter.drive.type !== 'manual'
                ? 'height_box_electrical'
                : 'height_box';
        let fieldBoxValue = 0;
        const staticConsole = this.configuratorsDataService.data.rollerShutterConsolesPrices?.[conf.RollerShutter.system.id]?.find((el) =>
                el.height_min <= conf.Height
                && el.height_max >= conf.Height
                && el.width_min <= conf.Width
                && el.width_max >= conf.Width
        );
        const grayPolystyrene = conf.RollerShutter.grayPolystyrene;
        if (
            conf.RollerShutter.system
            && conf.RollerShutter.system.box_heights
            && conf.RollerShutter.system.box_heights.length
        ) {
            filteredBoxHeights = conf.RollerShutter.system.box_heights
                .filter(el => (
                        el.height > 0
                        && el.options.some(opt => (
                                opt.height >= (addBox ? conf.RollerShutter.realRollerHeight
                                    : (height ? height : conf.RollerShutter.realRollerHeight))
                                && (!opt.profile || opt.profile === conf.RollerShutter.profile?.id)
                                && (!opt.reel || opt.reel === conf.RollerShutter.roundReel?.id)
                                && (!opt.hanger
                                    || (conf.RollerShutter.hanger
                                        && opt.hanger === conf.RollerShutter.hanger?.id))
                                && (!opt.driveType || opt.driveType === conf.RollerShutter.drive?.id)
                                && opt.mosquito
                                === conf.RollerShutter.shutters.some(e => e.mosquito)
                                && (!opt.staticConsole || !!staticConsole === !!+opt.staticConsole)
                                && (!opt.grayPolystyrene || !!grayPolystyrene === !!+opt.grayPolystyrene)
                            ))
                    ))
                .map(el => el.height);
        }

        if (
            !filteredBoxHeights.length
            && Common.isDefined(rollerPricesFiltered)
            && Common.isDefined(rollerPricesFiltered[0])
        ) {
            rollerPriceData = rollerPricesFiltered[0].data.sort(
                (a, b) => b.height_to - a.height_to
            );

            for (i = 0; i < rollerPriceData.length; i++) {
                fieldBoxValue = rollerPriceData[i][fieldBoxHeight]
                    ? rollerPriceData[i][fieldBoxHeight]
                    : rollerPriceData[i].height_box;
                if (boxHeightsList.indexOf(fieldBoxValue) === -1) {
                    boxHeights.push({
                        to: rollerPriceData[i].height_to,
                        height: fieldBoxValue,
                    });
                    boxHeightsList.push(fieldBoxValue);
                }
            }
            filteredBoxHeights = boxHeights
                .filter(el => {
                    let localHeight = comparedHeight;
                    if (comparedHeightWithBoxHeight) {
                        localHeight = conf.Height + Number(el.height);
                    }
                    return ~~el.to >= localHeight;
                })
                .map(el => el.height * 1)
                .sort((a, b) => a - b);

            if (filteredBoxHeights.length === 0) {
                let firstComparedHeight = comparedHeight;
                if (comparedHeightWithBoxHeight) {
                    firstComparedHeight = conf.Height + Number(boxHeights[0].height);
                }
                let lastComparedHeight = comparedHeight;
                if (comparedHeightWithBoxHeight) {
                    lastComparedHeight =
                        conf.Height + Number(boxHeights[boxHeights.length - 1].height);
                }
                if (firstComparedHeight > Number(boxHeights[0].to)) {
                    filteredBoxHeights.push(Number(boxHeights[0].height));
                } else if (lastComparedHeight < Number(boxHeights[boxHeights.length - 1].to)) {
                    filteredBoxHeights.push(Number(boxHeights[boxHeights.length - 1].height));
                }
            }
        }
        this.defaultRealBoxHeight = filteredBoxHeights[0];
        conf.RollerShutter.originalBoxHeight = filteredBoxHeights[0];

        core.clear(this.boxSizes);
        for (i = 0; i < filteredBoxHeights.length; i++) {
            this.boxSizes.push(filteredBoxHeights[i]);
        }
        if (this.boxSizes.length === 0 && WindowActiveConfiguration.is(conf)) {
            if (
                this.config().IccConfig.Configurators.extendedRollerInWindow !== 'full'
                || !this.config().IccConfig.Configurators.alwaysShowRollerStep
            ) {
                conf.hasRoller = false;
            }
            if (!this.config().IccConfig.Configurators.alwaysShowRollerStep) {
                this.stepsService.disable('rollershutter');
            }
        }
        if (((this.boxSizes.indexOf(conf.RollerShutter.realBoxHeight) === -1 || alwaysChange) && !this.configurationsService.conf.Current.RollerShutter.boxHeightChangedManually) || this.boxSizes.indexOf(conf.RollerShutter.realBoxHeight) === -1) {
            if (this.configurationsService.conf.Current.RollerShutter.boxHeightChangedManually && this.boxSizes.indexOf(conf.RollerShutter.realBoxHeight) === -1){
                this.setBoxHeightChangedManually(false);
            }
            if(conf.RollerShutter?.type?.measureType === 'with_box' && alwaysChange && conf.RollerShutter.realBoxHeight !== undefined && this.defaultRealBoxHeight !== undefined) {
                for (i = conf.RollerShutter.shutters.length - 1; i >= 0; i--) {
                    conf.RollerShutter.shutters[i].realHeight +=
                    (conf.RollerShutter.realBoxHeight - this.defaultRealBoxHeight);
                }
            }

            conf.RollerShutter.realBoxHeight = this.defaultRealBoxHeight;
            }
        conf.RollerShutter.boxHeightLevel = this.boxSizes.indexOf(conf.RollerShutter.realBoxHeight);
        this.priceService.count();
        this.parametersService.count(conf);
        return filteredBoxHeights;
    }

    /**
     * Funkcja odświeżajaca proporcje
     */
    refreshRatio() {
        let destWidth, destHeight;
        if (
            this.currentConfiguratorService.conf === 'window'
            || this.currentConfiguratorService.conf === 'hs'
            || this.currentConfiguratorService.conf === 'sliding_door'
            || this.currentConfiguratorService.conf === 'folding_door'
            || this.configurationsService.conf.Current.type === 'roller_shutter'
            || this.configurationsService.conf.Current.type === 'external_blind'
        ) {
            if (
                this.currentConfiguratorService.conf === 'window'
                || this.currentConfiguratorService.conf === 'hs'
                || this.currentConfiguratorService.conf === 'sliding_door'
                || this.currentConfiguratorService.conf === 'folding_door'
            ) {
                this.configurationsService.conf.Current.RollerShutter.ratio = 0;
                this.onBoxHeightChange();
            } else {
                if (!this.config().IccConfig.Configurators.roller_shutter.extraDimensionsOptions) {
                    destWidth = 320;
                    destHeight = 220;
                } else {
                    destWidth = 300;
                    destHeight = 200;
                }
                const ratioX =
                    destWidth / this.configurationsService.conf.Current.RollerShutter.realBoxWidth;
                const ratioY =
                    destHeight
                    / this.configurationsService.conf.Current.RollerShutter.realRollerHeight;
                this.configurationsService.conf.Current.RollerShutter.ratio =
                    ratioX * this.configurationsService.conf.Current.RollerShutter.realRollerHeight
                        < destHeight
                        ? ratioX
                        : ratioY;
            }
            this.loadBoxHeights(undefined, true);
        }
    }

    /**
     * Funkcja
     */
    toggleMosquito(checked?: boolean, index?: number) {
        if (isDefined(checked) && isDefined(index)) {
            this.configurationsService.conf.Current.RollerShutter.shutters[
                index
            ].mosquito = checked;
        }
        this.loadBoxHeights();
        this.setBoundaryDimensionsFromPrices();
        this.priceService.count();
        this.parametersService.count(this.configurationsService.conf.Current);
    }

    /**
     * Aktualizuje wysokość skrzynki w px
     */
    onBoxHeightChange(realBoxHeight = null, conf = this.configurationsService.conf.Current) {
        let previousBoxHeight;
        if (realBoxHeight) {
            previousBoxHeight = this.configurationsService.conf.Current.RollerShutter.realBoxHeight
            this.configurationsService.conf.Current.RollerShutter.realBoxHeight = realBoxHeight;
            if (conf.RollerShutter?.type?.measureType === 'with_box') {
                this.configurationsService.conf.Current.RollerShutter.shutters.map(shutter => {

                    const shutterHeight = (shutter.realHeight + previousBoxHeight - realBoxHeight);
                    const box = conf.RollerShutter?.system?.box_heights?.find(p => p.height === realBoxHeight);

                    const boxValue = this.getHighestRollerShutterBoxHeightValue(box, conf);
                    if (boxValue > 0 && shutterHeight > boxValue) {
                        shutter.realHeight = boxValue;
                    } else {
                        shutter.realHeight += previousBoxHeight - realBoxHeight;
                    }

                });
            }
        }
        this.configurationsService.conf.Current.RollerShutter.boxHeight =
            this.configurationsService.conf.Current.RollerShutter.realBoxHeight
            * this.configurationsService.conf.Current.RollerShutter.ratio;
        if (
            this.configurationsService.conf.Current.RollerShutter.realBoxHeight
            !== this.defaultRealBoxHeight
        ) {
            this.configurationsService.conf.Current.RollerShutter.changedSize = true;
        } else {
            this.configurationsService.conf.Current.RollerShutter.changedSize = false;
        }
        this.configurationsService.conf.Current.RollerShutter.boxHeightLevel = this.boxSizes.indexOf(
            this.configurationsService.conf.Current.RollerShutter.realBoxHeight
        );
        this.priceService.count();
        this.parametersService.count(this.configurationsService.conf.Current);
        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
        this.eventBusService.post({ key: 'rollerBoxHeightChanged', value: null });
    }

    private getHighestRollerShutterBoxHeightValue(box, conf = this.configurationsService.conf.Current): number {
        if (!box) {
            return;
        }

        return box?.options
            .filter(
                (opt) =>
                    (!opt.profile || opt.profile === conf.RollerShutter.profile.id) &&
                    (!opt.reel || opt.reel === conf.RollerShutter.roundReel.id) &&
                    (!opt.hanger ||
                        (conf.RollerShutter.hanger &&
                            opt.hanger === conf.RollerShutter.hanger.id)) &&
                    (!opt.driveType || opt.driveType === conf.RollerShutter.drive.id) &&
                    opt.mosquito === conf.RollerShutter.shutters.some((e) => e.mosquito)
            )
            .map((p) => p.height)
            .reduce((a, b) => Math.max(a, b), 0);
    }

    /**
     * Ustawia wymiary rolety poazujace do konfiguracji
     */
    setDimensions() {
        const roller = this.configurationsService.conf.Current.RollerShutter;

        if (
            (this.configurationsService.conf.Current.type === 'window'
                || this.configurationsService.conf.Current.type === 'hs'
                || this.configurationsService.conf.Current.type === 'folding_door'
                || this.configurationsService.conf.Current.type === 'sliding_door'
            )
            && Common.isDefined(roller.shutters)
        ) {
            let width = roller.realBoxWidth;
            let widthLeft = this.configurationsService.conf.Current.Width;
            let maxHeight = 0;

            for (let i = roller.shutters.length - 1; i >= 0; i--) {
                width -= roller.shutters[i].realWidth;
                if (widthLeft - width < 200) {
                    roller.shutters[i].realWidth = 200;
                } else {
                    roller.shutters[i].realWidth = widthLeft - width;
                }
                roller.shutters[i].width = roller.shutters[i].realWidth * roller.ratio;
                widthLeft -= roller.shutters[i].realWidth;

                roller.shutters[i].realHeight =
                    this.configurationsService.conf.Current.Height
                    + roller.shutters[i].railsHeightModify;
                roller.shutters[i].height = roller.shutters[i].realHeight * roller.ratio;
                if (roller.shutters[i].realHeight > maxHeight) {
                    maxHeight = roller.shutters[i].realHeight;
                }
            }
            roller.realRollerHeight = maxHeight;
            roller.realBoxWidth = this.configurationsService.conf.Current.Width;
            this.priceService.count();
            this.parametersService.count(this.configurationsService.conf.Current);
        }
    }

    checkShuttersConstructionLimitations() {
        const conf = this.configurationsService.conf.Current;
        if (
            (WindowActiveConfiguration.is(conf) && !conf.hasRoller)
            || Common.isUndefined(conf.RollerShutter.shutters)
            || !Common.isObject(conf.RollerShutter.profile)
            || conf.RollerShutter.shutters.length === 0
            || (conf.type === 'window' && !conf.hasRoller)
            || !Common.isObject(conf.RollerShutter.system)
            || Common.isUndefined(conf.RollerShutter.system.id)
        ) {
            return;
        }

        const sizeRange = this.configuratorsDataService.data.sizeRanges.find(
            range => Number(range.id) === Number(conf.RollerShutter.profile.size_range_id)
        );

        // Jeżeli opcja automatycznego wyboru pancerza jest włączona,
        // to do wyboru powinny być tylko dostępne pancerze, które spełniają ograniczenia wymiarowe
        if (this.config().IccConfig.Configurators.autoProfileChoice) {
            this.profiles = this.profilesForSystem.filter(profile => {
                const profileSizeRange = this.configuratorsDataService.data.sizeRanges.find(
                    range => Number(range.id) === Number(profile.size_range_id)
                );
                return (!profile.max_area || ((conf.Width * conf.Height) / 1000000 <= Number(profile.max_area))
                    && (!profileSizeRange || core.pointInPolygon(profileSizeRange.sizes, conf.Width, conf.Height)));
            });
        }

        if (
            !conf.RollerShutter.shutters.every(shutter => {
                let width = shutter.realWidth;
                let height = shutter.realHeight;
                if (this.config().IccConfig.Configurators.roller_shutter.realDimensions) {
                    const leftGuideRail = this.getGuide(shutter.id, 'left');
                    const rightGuideRail = this.getGuide(shutter.id, 'right');
                    const slat = this.getSlat(shutter.id);
                    if (leftGuideRail && rightGuideRail) {
                        this.issuesService.unregister('no-guide-rails', conf);
                        const leftGuideRailProfile = this.guideProfiles.find(
                            el => el.id === leftGuideRail.id
                        );
                        const rightGuideRailProfile = this.guideProfiles.find(
                            el => el.id === rightGuideRail.id
                        );
                        if (leftGuideRailProfile && rightGuideRailProfile) {
                            width -=
                                leftGuideRailProfile.widthOut
                                - leftGuideRailProfile.depth
                                + rightGuideRailProfile.widthOut
                                - rightGuideRailProfile.depth;
                        }
                    } else {
                        this.issuesService.simpleRegister(
                            'no-guide-rails',
                            'Brak pasujących prowadnic',
                            this.translateService.instant('WINDOW|Brak pasujących prowadnic'),
                            conf,
                            {
                                logLevel: IssueLevel.NONE
                            }
                        );
                    }
                    if (slat) {
                        this.issuesService.unregister('no-slat', conf);
                        const slatProfile = this.slatProfiles.find(el => el.id === slat.id);
                        height = Common.isNumber(conf.RollerShutter.profile.width)
                            ? Math.ceil(
                                (height
                                    + conf.RollerShutter.realBoxHeight / 2
                                    - slatProfile.widthOut)
                                / conf.RollerShutter.profile.width
                            )
                            * conf.RollerShutter.profile.width
                            + slatProfile.widthOut
                            : height;
                    } else {
                        this.issuesService.simpleRegister(
                            'no-slat',
                            'Brak pasujących listw końcowych',
                            this.translateService.instant('WINDOW|Brak pasujących listw końcowych'),
                            conf,
                            {
                                logLevel: IssueLevel.NONE
                            }
                        );
                    }
                }
                return (
                    (!parseFloat(conf.RollerShutter.profile.max_area)
                        || (height * width) / 1000000
                        <= parseFloat(conf.RollerShutter.profile.max_area))
                    && (!sizeRange || core.pointInPolygon(sizeRange.sizes, width, height))
                );
            })
        ) {
            // Jeżeli w configu jest włączona opcja autoProfileChoice,
            // to automatycznie wybierz pancerz, który spełnia ograniczenia wymiarowe
            if (this.config().IccConfig.Configurators.autoProfileChoice) {
                if (this.profiles) {
                    this.changeProfile(this.profiles[0]);
                }
            } else {
                this.issuesService.simpleRegister(
                    'no-matched-construction-limitations',
                    'UWAGA! Konstrukcja nie spełnia ograniczeń wymiarowych dla wybranego pancerza',
                    this.translateService.instant(
                        'WINDOW|UWAGA! Konstrukcja nie spełnia ograniczeń wymiarowych dla wybranego pancerza'
                    ),
                    conf,
                    {
                        logLevel: IssueLevel.NONE
                    }
                );
            }
        } else {
            this.issuesService.unregister('no-matched-construction-limitations', conf);
        }
    }
    validateDimension(dimension, isWidth, rollerShutterIndex) {
        const conf = this.configurationsService.conf.Current
        const matchedPrices = this.priceRollerService.getPricesForRoller(
            this.configurationsService.conf.Current.RollerShutter,
            this.configuratorsDataService.data.rollerPricesData,
            this.configurationsService.conf.Current.type,
            WindowActiveConfiguration.is(this.configurationsService.conf.Current) && this.configurationsService.conf.Current.Colors,
            false
        );
        let height = isWidth ? conf.RollerShutter.shutters[rollerShutterIndex].realHeight : dimension;
        const addBoxToHeight =  (isWidth || conf.RollerShutter.type.measureType !== 'with_box' || !conf.RollerShutter.type.measureType)
        if(this.config().IccConfig.Configurators.price.shutterHeightWithBox && addBoxToHeight) {
            height += conf.RollerShutter.realBoxHeight;
        }
        const width = isWidth ? dimension : conf.RollerShutter.shutters[rollerShutterIndex].realWidth;
        const hasPriceForWidth = this.hasPriceForDimension(width, true, matchedPrices);
        const hasPriceForHeight = this.hasPriceForDimension(height, false, matchedPrices);
        const configuratorDimensionalRestriction = this.config().IccConfig.Configurators.roller_shutter.configuratorDimensionalRestriction;

        const withMosquito = matchedPrices.filter(
            r => Number(r.with_mosquito) === 1
        );
        const noMosquito = matchedPrices.filter(
            r => Number(r.with_mosquito) === 0
        );
        const anyMosquito = matchedPrices.filter(
            r => Number(r.with_mosquito) === 2
        );
        const hasPrice = configuratorDimensionalRestriction
            ? (matchedPrices.length > 0 ? this.hasPrice(matchedPrices, width, height) : false)
            : [
                this.hasPrice(withMosquito, width, height),
                this.hasPrice(noMosquito, width, height),
                this.hasPrice(anyMosquito, width, height)
            ].filter(e => e === true).length > 0;

        if(hasPrice) {
            return true;
        } else {
            if (isWidth){
                return configuratorDimensionalRestriction ? !hasPriceForHeight && hasPriceForWidth : false;
            } else {
                return configuratorDimensionalRestriction ? hasPriceForHeight && !hasPriceForWidth : false;
            }
        }
    }
    validDimensions(
        { width, height, hasMosquito, matchedPrices, index },
        correctDimensions = {
            valid: false,
            messages: [],
        }
    ) {
        const rollerPricesMatchedWithMosquito = matchedPrices.filter(
            r => Number(r.with_mosquito) === 1
        );
        const rollerPricesMatchedNoMosquito = matchedPrices.filter(
            r => Number(r.with_mosquito) === 0
        );
        const rollerPricesMatchedAnyMosquito = matchedPrices.filter(
            r => Number(r.with_mosquito) === 2
        );

        const hasPriceWithMosquito = this.hasPrice(rollerPricesMatchedWithMosquito, width, height);
        const hasPriceWithoutMosquito = this.hasPrice(rollerPricesMatchedNoMosquito, width, height);
        const hasPriceAnyMosquito = this.hasPrice(rollerPricesMatchedAnyMosquito, width, height);
        correctDimensions.valid =
            correctDimensions.valid && (hasMosquito ? hasPriceWithMosquito : hasPriceWithoutMosquito) || hasPriceAnyMosquito;
        if (!correctDimensions.valid) {
            if (hasPriceWithMosquito && !hasPriceWithoutMosquito) {
                correctDimensions.messages.push({
                    type: 'should-be-mosquito',
                    index,
                });
            }
            if (!hasPriceWithMosquito && hasPriceWithoutMosquito) {
                correctDimensions.messages.push({
                    type: 'shouldnt-be-mosquito',
                    index,
                });
            }
            if (!hasPriceWithMosquito && !hasPriceWithoutMosquito && !hasPriceAnyMosquito) {
                this.dimensionsValid = {width: false, height: false};
                if (this.hasPrice(rollerPricesMatchedAnyMosquito, this.config().IccConfig.Configurators.roller_shutter?.dimensions?.width, height)){
                    this.dimensionsValid.height = true
                }
                if (this.hasPrice(rollerPricesMatchedAnyMosquito, width, this.config().IccConfig.Configurators.roller_shutter?.dimensions?.height)){
                    this.dimensionsValid.width = true
                }
                correctDimensions.messages.push({
                    type: 'incorrect-dimension',
                    index,
                });
            }
        } else {
            this.dimensionsValid = {width: true, height: true};
        }
        return correctDimensions;
    }

    private hasPrice(rollerPricesMatched: any[], width?: any, height?: any) {
        let hasPrice = false;
        if (rollerPricesMatched.length > 0) {
            const rollerPriceData = rollerPricesMatched[0].data;
            hasPrice =
                rollerPriceData.filter(boundary => {
                        const hasWidth = (!width
                            || (width <= Number(boundary.width_to)
                            && width >= Number(boundary.width_from)));
                        const hasHeight = (!height
                            || (height <= Number(boundary.height_to)
                            && height >= Number(boundary.height_from)));
                        let areaCorrect = true;
                        if(width && height && Common.isDefined(boundary.max_area) && boundary.max_area !== null && this.config().IccConfig.Configurators.roller_shutter.configuratorDimensionalRestriction) {
                            const area = (width * height) / 1000000;
                            areaCorrect = boundary.max_area >= area;
                        }
                        return hasWidth && hasHeight && areaCorrect;
                    }).length > 0;
        }
        return hasPrice;
    }

    private hasPriceForDimension(dimension, isWidth, matchedPrices) {
        if(matchedPrices.length === 0) {
            return false;
        }

        let fields: {from: string, to: string} = {from: 'width_from', to: 'width_to'};
        if(!isWidth) {
            fields = {from: 'height_from', to: 'height_to'};
        }
        const rollerPriceData = matchedPrices[0].data;
        const matchPriceToChangeDimensions =  rollerPriceData.filter(boundary =>
           (!dimension
                || (dimension <= Number(boundary[fields.to])
                && dimension >= Number(boundary[fields.from])))
        ).length > 0;
        if(!matchPriceToChangeDimensions) {
            return false
        }
        return true;
    }

    isRollerShutterAvailableToWindowSize(width, height) {
        return this.hasPrice(this.configuratorsDataService.data.rollerPricesData, width, height)
    }

    /**
     * Funkcja znajudujaca profil systemu
     * @param  {object} conf conf
     */
    loadProfilesBySystem(conf) {
        this.profilesForSystem = [];
        if (Common.isObject(conf.RollerShutter.system)) {
            this.profilesForSystem = this.allProfiles.filter(
                el =>
                    Common.isArray(el.systems)
                    && el.systems.indexOf(conf.RollerShutter.system.id) > -1
                    && el.type === 'roller_shutter_slat'
            );
        }
        if (
            this.config().IccConfig.Configurators.roller_shutter.autoProfileSelection
            && Common.isObject(conf.RollerShutter.colors.profile)
        ) {
            const ids = this.getProfileIdsForColor(conf);
            this.profilesForSystem = this.allProfiles.filter(
                (el) =>
                    ids.includes(el.id) &&
                    el.systems?.indexOf(
                        this.configurationsService.conf.Current.RollerShutter.system.id
                    ) > -1
            );
        }
        this.profiles = this.profilesForSystem;
        const availProfile = this.profiles.some(
            el =>
                Common.isObject(conf.RollerShutter.profile)
                && el.id === conf.RollerShutter.profile.id
        );
        if (!availProfile) {
            this.setDefaultProfile(conf);
        }
    }

    /**
     * Ustawia domyślny profil
     * @param {object} conf conf
     */
    setDefaultProfile(conf) {
        if (Common.isDefined(this.profiles[0])) {
            conf.RollerShutter.profile = core.copy(this.getDefaultProfile());
            this.loadRoundReelsByProfile(conf);
            this.setGuideProfiles(conf);
            this.setSlatProfiles(conf);
        }
    }

    /**
     * Funkcja zmieniajca profil
     */
    changeProfile(profile) {
        this.configurationsService.conf.Current.RollerShutter.profile = profile;
        this.loadRoundReelsByProfile(this.configurationsService.conf.Current);
        this.loadBoxHeights();
        this.setBoundaryDimensionsFromPrices();
        this.setGuideProfiles();
        this.setSlatProfiles();
        this.priceService.count();
        this.parametersService.count(this.configurationsService.conf.Current);
        if (!this.config().IccConfig.Configurators.roller_shutter.autoProfileSelection) {
            this.colorsService.init();
        }
    }

    /**
     * Funkcja ładująca rury nawojowe pasujące do pancerza.
     * @param  {object} conf conf
     */
    loadRoundReelsByProfile(conf) {
        core.clear(this.roundReels);
        if (Common.isObject(conf.RollerShutter.profile)) {
            this.roundReels.push(
                ...this.allRoundReels.filter(
                    el => (Common.isArray(el.profiles)
                        && el.profiles.indexOf('' + conf.RollerShutter.profile.id) > -1
                        && el.systems?.indexOf('' + conf.RollerShutter.system.id) > -1
                        && conf.RollerShutter.system.box_heights.filter(height=>height.options.some(option => option.reel === el.id || option.reel === null))
                        && (!el.shutter_max_width
                            || parseFloat(el.shutter_max_width) >= conf.RollerShutter.realBoxWidth))
                )
            );
        }
        const availRoundReel = this.roundReels.some(
            el =>
                Common.isObject(conf.RollerShutter.roundReel)
                && el.id === conf.RollerShutter.roundReel.id
        );
        if (!availRoundReel) {
            this.setDefaultRoundReel(conf);
        }
    }

    /**
     * Ustawia domyślną rurę nawojową.
     * @param {object} conf conf
     */
    setDefaultRoundReel(conf) {
        const config = core.copy(conf);
        this.roundReels.forEach((reel) => {
            config.RollerShutter.roundReel = reel;
            if (
                this.loadBoxHeights(undefined, false, config).length > 0 &&
                this.loadBoxHeights(undefined, false, config)[0] !== 0
            ) {
                conf.RollerShutter.roundReel = core.copy(reel);
            }
        });

        const roundReelBasedOnSorting = this.roundReels?.reduce((prev, curr) =>
            prev?.order < curr?.order ? prev : curr
        );

        if (roundReelBasedOnSorting) {
            conf.RollerShutter.roundReel = roundReelBasedOnSorting;
        }
    }

    /**
     * Funkcja zmieniajca rurę nawojową
     */
    changeRoundReel(roundReel) {
        this.configurationsService.conf.Current.RollerShutter.roundReel = roundReel;
        this.loadBoxHeights();
        this.setBoundaryDimensionsFromPrices();
        this.priceService.count();
        this.parametersService.count(this.configurationsService.conf.Current);
    }

    /**
     * Funkcja otwierajaca okno modal
     */
    openModalMontagesInfo(details) {
        this.modalService.open({
            templateUrl: 'modalMontagesInfo.html',
            controller: 'ModalMontagesInfoCtrl as minfo',
            pageComponent: MontagesInfoPageComponent,
            resolve: { details: () => details },
        });
    }

    /**
     * Funkcja otwierajaca okno modal do wyboru szprosów
     * @param  {object} rollerId    Typ koloru
     * @param  {object} conf      Konfiguracja
     */
    openModalGuideSelection(rollerId, side, conf) {
        this.setGuideProfiles();

        const guideProfiles = side.includes('common')
            ? this.guideProfiles.filter(p => p.options.includes('double_guide'))
            : this.guideProfiles.filter(p => !p.options.includes('double_guide'));
        const selectedProfile = this.getGuide(rollerId, side);

        console.log('selectedProfile', selectedProfile, guideProfiles);
        const modalInstance = this.modalService.open({
            component: 'profilesModal',
            pageComponent: ProfilesListPageComponent,
            resolve: {
                profiles: () => guideProfiles,
                profilesPrices: () => this.configuratorsDataService.data.profilesPrices,
                system: () => null,
                type: () => 'roller_guide',
                selectedProfile: () =>
                    selectedProfile
                        ? this.guideProfiles.find(p => p.id === selectedProfile.id)
                        : null,
                currency: () => this.config().currency || this.stateService.state.offers[0].doc.currency,
                color: () => null,
                wood: () => null,
                hiddenPrice: () => true
            },
        });

        modalInstance.result.then(
            data => {
                if (data && side) {
                    this.saveGuideProfile(rollerId, data, side);
                }
            },
            res => null
        );
    }

    /**
     * Funkcja otwierajaca okno modal do wyboru listw koncowych
     * @param  {object} rollerId    ID shuttera
     * @param  {object} conf      Konfiguracja
     */
    openModalSlatSelection(rollerId, conf) {
        this.setSlatProfiles();

        const selectedProfile = this.getSlat(rollerId);
        const modalInstance = this.modalService.open({
            component: 'profilesModal',
            pageComponent: ProfilesListPageComponent,
            resolve: {
                profiles: () => this.slatProfiles,
                profilesPrices: () => this.configuratorsDataService.data.profilesPrices,
                system: () => null,
                type: () => 'roller_slat',
                selectedProfile: () =>
                    selectedProfile
                        ? this.slatProfiles.find(p => p.id === selectedProfile.id)
                        : null,
                currency: () => this.config().currency || this.stateService.state.offers[0].doc.currency,
                color: () => null,
                wood: () => null,
                hiddenPrice: () => true
            },
        });

        modalInstance.result.then(
            data => {
                if (data) {
                    this.saveSlatProfile(rollerId, data);
                }
            },
            res => null
        );
    }

    async openProfilesModal(): Promise<Profile> {
        const conf = this.configurationsService.conf.Current;
        const modalInstance = this.modalService.open({
            component: 'profilesModal',
            pageComponent: ProfilesListPageComponent,
            resolve: {
                profiles: () => this.profiles,
                profilesPrices: () => this.configuratorsDataService.data.profilesPrices,
                system: () => null,
                type: () => 'roller_shutter_slat',
                selectedProfile: () => conf.RollerShutter.profile,
                currency: () =>
                    this.config().currency || this.stateService.state.offers[0].doc.currency,
                color: () => null,
                wood: () => null,
                hiddenPrice: () => true
            },
        });

        return modalInstance.result;
    }

    async selectProfile() {
        const profile = await this.openProfilesModal();
        if (profile) {
            this.changeProfile(profile);
        }
    }

    getDefaultProfile() {
        const conf = this.configurationsService.conf.Current;
        if (this.config().IccConfig.Configurators.roller_shutter.autoProfileSelection) {
            if (conf.RollerShutter.colors.profile && conf.RollerShutter.colors.profile.groups) {
                const colorProfiles = this.getProfileIdsForColor(conf);
                return (
                    this.allProfiles.find((profile) =>
                        colorProfiles.some((p) => Number(p) === Number(profile.id)) && profile.systems?.indexOf(
                        this.configurationsService.conf.Current.RollerShutter.system.id) > -1
                    ) || this.profiles[0]
                );
            }
        } else {
            return this.profiles[0];
        }
    }

    getProfileIdsForColor(conf: RollerShutterActiveConfiguration | WindowActiveConfiguration) {
        const colorProfiles =
            conf.RollerShutter.colors.profile && conf.RollerShutter.colors.profile.groups
                ? conf.RollerShutter.colors.profile.groups
                    .map(g =>
                        this.colorsService.profileGroups.find(pg => Number(pg.id) === Number(g))
                    )
                    .filter(g => g)
                    .map(g => g.profiles)
                    .reduce((prev, cur) => {
                        prev.push(...cur);
                        return prev;
                    }, [])
                : [];
        return colorProfiles;
    }
    setBoxHeightChangedManually(value: boolean, conf = this.configurationsService.conf.Current) {
        conf.RollerShutter.boxHeightChangedManually = value;
    }

    getProfiles() {
        return this.profiles;
    }

    /**
     * Funkcja aktualizująca wymiar rolety na podstawie wymiaru okna
     * @param  {number} oldWidth  Szerokość okna przed zmianą
     * @param  {number} oldHeight  Wysokość okna przed zmianą
     * @param  {object} conf      Konfiguracja
     */
    updateDimensionFromWindow(oldHeight: number, currentWidth: number, currentHeight: number, conf = this.configurationsService.conf.Current) {
        const differentWidthToHeight = conf.RollerShutter.realRollerHeight - oldHeight;
        const width = currentWidth;
        const height = currentHeight + differentWidthToHeight;
        conf.RollerShutter.shutters = conf.RollerShutter.shutters.map(shutter => {
            const ratioWidth = shutter.realWidth / conf.RollerShutter.realBoxWidth;
            const ratioHeight = shutter.realHeight / conf.RollerShutter.realRollerHeight;
            shutter.realWidth = ratioWidth * width;
            shutter.realHeight = ratioHeight * height;
            return shutter;
        });

        this.updateDimensions();
    }

    /**
     * Funkcja resetująca wymiar rolety do wymiaru okna
     * @param  {number} oldWidth  Szerokość okna przed zmianą
     * @param  {number} oldHeight  Wysokość okna przed zmianą
     * @param  {object} conf      Konfiguracja
     */
    resetDimensionToWindowSize(conf = this.configurationsService.conf.Current) {
        const rollerShutterHeightRatio = conf.RollerShutter.realRollerHeight < 1 ? 1 : undefined;
        const rollerShutterWidthRatio = conf.RollerShutter.realBoxWidth < 1 ? 1 / conf.RollerShutter.shutters.length : undefined;
        const windowConf = this.configurationsService.getOrInitConfiguratorConfigurations('window');
        if (windowConf?.Current) {
            conf.RollerShutter.shutters = conf.RollerShutter.shutters.map(shutter => {
                const ratioHeight = rollerShutterHeightRatio ?? shutter.realHeight / conf.RollerShutter.realRollerHeight;
                const ratioWidth = rollerShutterWidthRatio ?? shutter.realWidth / conf.RollerShutter.realBoxWidth;
                shutter.realHeight = ratioHeight * windowConf.Current.Height;
                shutter.realWidth = ratioWidth * windowConf.Current.Width;
                return shutter;
            });
        }

        this.updateDimensions();
    }

    /**
     * Funkcja sprawdzająca czy wyświetlić przycisk do resetowania wymiarów rolety do wymiarów okna
     * @param  {object} conf      Konfiguracja
     */
    showResetDimensionButon(conf = this.configurationsService.conf.Current) {
        const windowConf = this.configurationsService.getOrInitConfiguratorConfigurations('window');
        return windowConf.Current.Width !== conf.RollerShutter.realBoxWidth || windowConf.Current.Height !== conf.RollerShutter.realRollerHeight;
    }

    /**
     * Funkcja pobierająca zakres rozmiarów rolety
     */
    getSizeRange(full = true) {
        const conf = this.configurationsService.conf.Current
        const matchedPrices = this.priceRollerService.getPricesForRoller(
            conf.RollerShutter,
            this.configuratorsDataService.data.rollerPricesData,
            conf.type,
            WindowActiveConfiguration.is(conf) && conf.Colors,
            false,
            false
        );
        const result: {minWidth: number, maxWidth: number, minHeight:number, maxHeight: number}[] = [];
        const shutterSizes: {width: number, height: number}[] = [];
        const boxHeight = conf.RollerShutter.realRollerHeight + conf.RollerShutter.realBoxHeight;
        const boxWidth = conf.RollerShutter.realBoxWidth;
        let realBoxHeight = 0;
        if (conf.RollerShutter.type.measureType !== 'with_box' || !conf.RollerShutter.type.measureType) {
            realBoxHeight = conf.RollerShutter.realBoxHeight;
        }

        if(matchedPrices.length > 0) {
            const matchedPricesData = core.copy(matchedPrices[0].data);
            let matchedPricesDataWidth = matchedPricesData;
            let matchedPricesDataHeight = matchedPricesData;
            if(!full) {
                matchedPricesDataWidth = matchedPricesData.filter(matchedPrice => matchedPrice.height_from <= boxHeight && matchedPrice.height_to >= boxHeight);
                matchedPricesDataHeight = matchedPricesData.filter(matchedPrice => matchedPrice.width_from <= boxWidth && matchedPrice.width_to >= boxWidth);
            }
            conf.RollerShutter.shutters.forEach((shutter, index) => {
                shutterSizes[index] = {
                    width: shutter.realWidth,
                    height: shutter.realHeight
                }
            });

            conf.RollerShutter.shutters.forEach((shutter, index) => {
                const anotherShutterSize =
                    shutterSizes.filter((shutterSize, shutterIndex) => shutterIndex !== index)
                        .reduce((prev, current) => prev + current.width, 0)

                result[index] = {
                    maxWidth: matchedPricesDataWidth.length > 0 ? matchedPricesDataWidth.reduce((prev, current) => Number(prev.width_to) > Number(current.width_to) ? prev : current).width_to - anotherShutterSize: -1,
                    minWidth: matchedPricesDataWidth.length > 0 ? matchedPricesDataWidth.reduce((prev, current) => Number(prev.width_from) < Number(current.width_from) ? prev : current).width_from : -1,
                    maxHeight: matchedPricesDataHeight.length > 0 ? matchedPricesDataHeight.reduce((prev, current) => Number(prev.height_to) > Number(current.height_to) ? prev : current).height_to - realBoxHeight : -1,
                    minHeight: matchedPricesDataHeight.length > 0 ? matchedPricesDataHeight.reduce((prev, current) => Number(prev.height_from) < Number(current.height_from) ? prev : current).height_from - realBoxHeight : -1,
                };
                if(Common.isDefined(matchedPrices[0].max_area) && matchedPrices[0].max_area !== null && this.config().IccConfig.Configurators.roller_shutter.configuratorDimensionalRestriction) {
                    const maxWidth = Math.floor((matchedPrices[0].max_area * 1000000) / boxHeight);
                    const maxHeight = Math.floor((matchedPrices[0].max_area * 1000000) / boxWidth) - realBoxHeight;
                    result[index].maxWidth =  result[index].maxWidth !== -1 && result[index].maxWidth > maxWidth ? maxWidth : result[index].maxWidth;
                    result[index].maxHeight = result[index].maxHeight !== -1 && result[index].maxHeight > maxHeight ? maxHeight : result[index].maxHeight;
                 }
            });
        }
        if(result.length > 0) {
            return result;
        } else {
            return this.getAllSizeRestriction();
        }
    }

    getAllSizeRestriction() {
        const conf = this.configurationsService.conf.Current
        const result: {minWidth: number, maxWidth: number, minHeight:number, maxHeight: number}[] = [];
        const matchedPrices = this.configuratorsDataService.data.rollerPricesData;
        const shutterSizes: {width: number, height: number}[] = [];
        const boxHeight = conf.RollerShutter.realRollerHeight + conf.RollerShutter.realBoxHeight;
        const boxWidth = conf.RollerShutter.realBoxWidth;
        let realBoxHeight = 0;
        if (conf.RollerShutter.type.measureType !== 'with_box' || !conf.RollerShutter.type.measureType) {
            realBoxHeight = conf.RollerShutter.realBoxHeight;
        }
        if(matchedPrices.length > 0) {
            const matchedPricesData = core.copy(matchedPrices[0].data);
            conf.RollerShutter.shutters.forEach((shutter, index) => {
                shutterSizes[index] = {
                    width: shutter.realWidth,
                    height: shutter.realHeight
                }
            });

            conf.RollerShutter.shutters.forEach((shutter, index) => {
                const anotherShutterSize =
                    shutterSizes.filter((shutterSize, shutterIndex) => shutterIndex !== index)
                        .reduce((prev, current) => prev + current.width, 0)

                result[index] = {
                    maxWidth: matchedPricesData.reduce((prev, current) => Number(prev.width_to) > Number(current.width_to) ? prev : current).width_to - anotherShutterSize,
                    minWidth: matchedPricesData.reduce((prev, current) => Number(prev.width_from) < Number(current.width_from) ? prev : current).width_from,
                    maxHeight: matchedPricesData.reduce((prev, current) => Number(prev.height_to) > Number(current.height_to) ? prev : current).height_to - realBoxHeight,
                    minHeight: matchedPricesData.reduce((prev, current) => Number(prev.height_from) < Number(current.height_from) ? prev : current).height_from - realBoxHeight,
                };

                if(Common.isDefined(matchedPrices[0].max_area) && matchedPrices[0].max_area !== null && this.config().IccConfig.Configurators.roller_shutter.configuratorDimensionalRestriction) {
                    const maxWidth = Math.floor((matchedPrices[0].max_area * 1000000) / boxHeight);
                    const maxHeight = Math.floor((matchedPrices[0].max_area * 1000000) / boxWidth) - realBoxHeight;
                    result[index].maxWidth = result[index].maxWidth > maxWidth ? maxWidth : result[index].maxWidth;
                    result[index].maxHeight = result[index].maxHeight > maxHeight ? maxHeight : result[index].maxHeight;
                 }
            });
        }
        return result;
    }

    getMosquitoSizeRestriction() {
        const conf = this.configurationsService.conf.Current
        let result: {minWidth: number, maxWidth: number, minHeight:number, maxHeight: number} = {minWidth: 0, maxWidth: 0, minHeight: 0, maxHeight: 0};
        const matchedPrices = this.priceRollerService.getPricesForRoller(
            conf.RollerShutter,
            this.configuratorsDataService.data.rollerPricesData,
            conf.type,
            WindowActiveConfiguration.is(conf) && conf.Colors,
            false,
            false
        );

        if(matchedPrices.length > 0) {
            const matchedPricesData = core.copy(matchedPrices[0].data);
            let mosquitoPriceLists;
            let mosquitoPriceListId;
            switch(matchedPrices[0].with_mosquito) {
                case "1":
                    result = {
                        maxWidth: matchedPricesData.reduce((prev, current) => Number(prev.width_to) > Number(current.width_to) ? prev : current).width_to,
                        minWidth: matchedPricesData.reduce((prev, current) => Number(prev.width_from) < Number(current.width_from) ? prev : current).width_from,
                        maxHeight: matchedPricesData.reduce((prev, current) => Number(prev.height_to) > Number(current.height_to) ? prev : current).height_to,
                        minHeight: matchedPricesData.reduce((prev, current) => Number(prev.height_from) < Number(current.height_from) ? prev : current).height_from,
                    };
                    break;
                case "2":
                    mosquitoPriceLists = this.configuratorsDataService.data.productPricelists;
                    mosquitoPriceListId = matchedPrices[0].product_pricelist_id;
                    if(mosquitoPriceLists.productPricelists && mosquitoPriceListId > 0 && mosquitoPriceListId in mosquitoPriceLists.productPricelists) {
                        const mosquitoPriceListsData = mosquitoPriceLists.productPricelists[mosquitoPriceListId].data;
                        result = {
                            maxWidth: mosquitoPriceListsData.reduce((prev, current) => Number(prev.width_to) > Number(current.width_to) ? prev : current).width_to,
                            minWidth: mosquitoPriceListsData.reduce((prev, current) => Number(prev.width_from) < Number(current.width_from) ? prev : current).width_from,
                            maxHeight: mosquitoPriceListsData.reduce((prev, current) => Number(prev.height_to) > Number(current.height_to) ? prev : current).height_to,
                            minHeight: mosquitoPriceListsData.reduce((prev, current) => Number(prev.height_from) < Number(current.height_from) ? prev : current).height_from,
                        };
                    }
                    break;
            }
        }

        return result
    }
}
