import { Inject, Injectable } from '@angular/core';
import {
    APP_CONFIG,
    AppConfigFactory,
    ConfigurationsService,
    ConfiguratorsDataService,
    EventBusService,
    ParametersService,
} from '@icc/common';
import { PriceService } from '@icc/price/b2b';
import { RollerDimensionsService } from './dimensions.service';
import { GrayPolystyrene } from '@icc/common/configurations/parts/roller_shutter';

@Injectable({
    providedIn: 'root',
})
export class GrayPolystyreneService {
    grayPolystyrene: {
        id: number;
        name: string;
        price: number;
        windowLinesIds: number[];
        priceType: string;
        noPrice?: boolean;
    }[] = [];

    constructor(
        private eventBusService: EventBusService,
        private configuratorsDataService: ConfiguratorsDataService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        public configurationsService: ConfigurationsService<'roller_shutter'>,
        private parametersService: ParametersService,
        private priceService: PriceService,
        private dimensionsService: RollerDimensionsService,
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
    }

    init() {
        this.grayPolystyrene =
            this.configuratorsDataService.data.rollerShutterPolystyrene || [];
    }

    availableGrayPolystyrene(conf = this.configurationsService.conf.Current) {
        return (
            this.config().IccConfig.Configurators.roller_shutter.grayPolystyrene &&
            this.grayPolystyrene?.length > 0 &&
            this.grayPolystyrene.some((gP) =>
                gP.windowLinesIds.map(Number).includes(+conf.RollerShutter?.type?.id)
            )
        );
    }

    toggleGrayPolystyrene(checked: boolean) {
        if (!checked) {
            this.setGrayPolystyrene(null);
        } else {
            const grayPolystyrene = this.grayPolystyrene.find(
                (gP) =>
                    gP.windowLinesIds.map(Number).includes(
                        +this.configurationsService.conf.Current.RollerShutter.type.id
                    )
            );
            this.setGrayPolystyrene(grayPolystyrene);
        }
    }

    setGrayPolystyrene(grayPolystyrene: GrayPolystyrene | null) {
        this.configurationsService.conf.Current.RollerShutter.grayPolystyrene = grayPolystyrene;
        this.eventBusService.post({
            key: 'grayPolystyreneChanged',
            value: grayPolystyrene,
        })
        this.dimensionsService.loadBoxHeights();
        this.dimensionsService.setBoundaryDimensionsFromPrices();
        this.priceService.count();
        this.parametersService.count(this.configurationsService.conf.Current);
    }

    validateGrayPolystyrene() {
        if (this.configurationsService.conf.Current.RollerShutter?.grayPolystyrene && this.availableGrayPolystyrene()) {
            const grayPolystyrene = this.grayPolystyrene.find(
                (gP) =>
                    gP.windowLinesIds.map(Number).includes(
                        +this.configurationsService.conf.Current.RollerShutter.type.id
                    )
            );
            this.setGrayPolystyrene(grayPolystyrene);
        } else {
            this.setGrayPolystyrene(null);
        }
    }
}
