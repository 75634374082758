import { Inject, Injectable } from '@angular/core';
import {
    APP_CONFIG,
    AppConfigFactory,
    ConfigurationsService,
    ConfiguratorsDataService,
    EventBusService,
    ProfilesService,
    ValidationService,
    WindowActiveConfiguration,
    core,
    logger,
} from '@icc/common';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { ColorRestrictionService } from '@icc/common/colors/colors-restriction.service';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import {
    IccAccessoryAccessory,
    IccColor,
    IccColorGroup,
    IccConstructColor,
    IccFilling,
    IccProfileSideColors,
    IccSideColors,
} from '@icc/common/data-types';
import { Side } from '@icc/common/data-types/ColorGroup';
import { ActiveSash } from '@icc/common/layout/active-sash';
import {
    InfoService,
    IssuesService,
    ModalService,
    isArray,
    isObject,
    isUndefined,
} from '@icc/configurator/shared';
import { Profile } from '@icc/window';
import { TranslateService } from '@ngx-translate/core';
import { ColorsPageComponent } from '../colors-page/colors-page.component';
import { DoorPortalsService } from '../door-portals/door-portals.service';
import {
    ColoredAccessories,
    ColoredElements,
    ColoredProfiles,
    ColoredSashes,
    ColoredSides,
    ProfileColoredSides,
} from './colored-elements';

type ColorElement = {
    element: ColoredElements;
    side?: ProfileColoredSides | ColoredSides;
    sash?: ColoredSashes;
    profile?: ColoredProfiles;
    accessory?: ColoredAccessories;
    alt?: boolean;
    intSash?: boolean;
};

type ColorMapping = {
    parent: ColorElement | null;
    condition?: (element?: ColorElement | null) => boolean;
    children: ColorElement[];
};

@Injectable()
export class NewColorsService {
    private configurators = [
        'window',
        'hs',
        'door',
        'folding_door',
        'sliding_door',
        'complementary_goods',
    ];

    colors: Record<number, IccColor> = {};
    constructColors: IccConstructColor[] = [];

    loadedData = false;

    // eslint-disable-next-line max-params
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private modalService: ModalService,
        private configurationsService: ConfigurationsService<'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private translateService: TranslateService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private eventBusService: EventBusService,
        private issuesService: IssuesService,
        private colorRestrictionService: ColorRestrictionService,
        private profilesService: ProfilesService,
        private doorPortalsService: DoorPortalsService,
        private validationService: ValidationService,
        private infoService: InfoService,
        private colorMappingService: ColorMappingService
    ) {
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });

        this.eventBusService.subscribe(['setLowThreshold', 'unsetLowThreshold', 'loadedProfiles'], (data) => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration) &&
                WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.validateElementColor(data.activeConfiguration as WindowActiveConfiguration, {
                    element: 'threshold',
                }, false, null, true);
            }
        });

        this.eventBusService.subscribe(['setFrameProfile'], (data) => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration) &&
                WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.validateElementColor(data.activeConfiguration as WindowActiveConfiguration, {
                    element: 'frame',
                    side: 'outer',
                }, false, null, true);
            }
        });

        this.eventBusService.subscribe('setMuntinType', (data) => {
            if (WindowActiveConfiguration.is(data.activeConfiguration)) {
                this.validateElementColor(data.activeConfiguration as WindowActiveConfiguration, {
                    element: 'muntin',
                    side: 'inner',
                });
                this.validateElementColor(data.activeConfiguration as WindowActiveConfiguration, {
                    element: 'muntin',
                    side: 'outer',
                });
            }
        });

        this.eventBusService.subscribe(['setDoorPortal', 'loadedProfiles'], (data) => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration) &&
                WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.validateElementColor(data.activeConfiguration as WindowActiveConfiguration, {
                    element: 'doorPortal',
                });
            }
        });
    }

    init() {
        this.loadData();
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }
        if (isUndefined(this.configurationsService.conf)) {
            return;
        }
        this.issuesService.addValidateFunction(this.validate.bind(this));
    }

    loadData() {
        this.colors = this.configuratorsDataService.data.colors ?? [];
        this.constructColors = this.configuratorsDataService.data.windowColorsAll ?? [];

        this.loadedData = true;
    }

    getMatchingColorsFor(
        conf: WindowActiveConfiguration,
        colorElement: ColorElement,
        forDefault = false
    ): IccColor[] {
        if (
            colorElement.element === 'frame' ||
            colorElement.element === 'sash' ||
            colorElement.element === 'lipping'
        ) {
            return this.getMatchingWindowColorsFor(
                conf,
                colorElement.element,
                colorElement.side,
                forDefault
            );
        }
        if (colorElement.element === 'filling') {
            return this.getMatchingFillingColorsFor(
                conf,
                colorElement.side ?? 'outer',
                colorElement.sash ?? 'activeDoorSash',
                colorElement.alt,
                forDefault
            );
        }
        if (colorElement.element === 'doorPortal') {
            return this.getMatchingDoorPortalColorsFor(conf);
        }
        if (colorElement.element === 'threshold') {
            return this.getMatchingThresholdColorsFor(conf);
        }
        if (colorElement.element === 'profile' && typeof colorElement.profile === 'object') {
            return this.getMatchingProfileColorsFor(conf, colorElement.profile, colorElement.side);
        }
        if (colorElement.element === 'muntin') {
            return this.getMatchingMuntinColorsFor(conf, colorElement.side ?? 'outer');
        }
        if (colorElement.element === 'accessory' && typeof colorElement.accessory === 'object') {
            return this.getMatchingAccessoryColorsFor(
                conf,
                colorElement.accessory,
                colorElement.side ?? 'outer'
            );
        }
        return [];
    }

    getMatchingWindowColorsFor(
        conf: WindowActiveConfiguration,
        element: 'frame' | 'sash' | 'lipping',
        side: ProfileColoredSides = 'outer',
        forDefault = false
    ) {
        if (!conf.System?.id) {
            return [];
        }
        if (element === 'lipping' && !conf.System.available_lipping_color) {
            return [];
        }
        if (element === 'lipping' && conf.System.lipping_color_based_on_frame_color) {
            element = 'frame';
        }
        const sides = this.getColorSides(element, side);
        const colorGroups = this.getColorGroupsForWindow(conf, sides);
        let matchingConstrColors = this.getMatchingWindowConstructColors(conf, colorGroups, sides);

        if (element === 'frame' || element === 'sash') {
            const profileColors = conf.Colors[element];
            if (side === 'outer' && conf.HasAlushell) {
                matchingConstrColors = this.handleWindowOuterSide(
                    conf,
                    matchingConstrColors,
                    profileColors
                );
            }

            if (side === 'inner') {
                matchingConstrColors = this.handleWindowInnerSide(
                    profileColors,
                    conf,
                    element,
                    matchingConstrColors
                );
            } else if (side === 'core') {
                matchingConstrColors = this.handleWindowCoreSide(
                    profileColors,
                    matchingConstrColors,
                    element
                );
            } else if (side === 'alushell') {
                if (!conf.HasAlushell) {
                    return [];
                }
                matchingConstrColors = this.handleWindowAlushellSide(conf, matchingConstrColors);
            }
        }

        if (side !== 'core') {
            this.sortConstrColors(matchingConstrColors, colorGroups);
        }

        if (conf.System?.default_market_configuration?.window_color_id && forDefault) {
            this.handleMarketWindowDefaultColor(matchingConstrColors, conf);
        }

        return this.getMatchingColorsFromConstrColors(matchingConstrColors);
    }

    private getMatchingColorsFromConstrColors(matchingConstrColors: IccConstructColor[]) {
        const matchingColors: IccColor[] = [];
        const matchingColorsIndexes: number[] = [];
        for (const constrColor of matchingConstrColors) {
            if (
                constrColor.colorId &&
                !matchingColorsIndexes.includes(constrColor.colorId) &&
                this.colors[constrColor.colorId]
            ) {
                matchingColorsIndexes.push(constrColor.colorId);
                matchingColors.push(this.colors[constrColor.colorId]);
            }
        }
        return matchingColors;
    }

    private handleMarketWindowDefaultColor(
        matchingConstrColors: IccConstructColor[],
        conf: WindowActiveConfiguration
    ) {
        // domyślny kolor umieszczamy na początku listy
        const defaultColorIndex = matchingConstrColors.findIndex(
            (k) => +k.id === +conf.System.default_market_configuration?.window_color_id
        );
        if (defaultColorIndex > -1) {
            const defaultColor = matchingConstrColors.splice(defaultColorIndex, 1)[0];
            matchingConstrColors.unshift(defaultColor);
        }
    }

    private handleWindowAlushellSide(
        conf: WindowActiveConfiguration,
        matchingConstrColors: IccConstructColor[]
    ) {
        if (conf.AlushellType === 'brushed') {
            const brushedColorGroups = this.getBrushedAlushellColorGroups();
            if (!brushedColorGroups.length) {
                this.infoService.showInfo(
                    this.translateService.instant(
                        'WINDOW|Brak zdefiniowanej grupy dla aluminium szczotkowanego'
                    ),
                    null
                );
            }
            matchingConstrColors =
                (brushedColorGroups.length > 0 &&
                    matchingConstrColors.filter((color) =>
                        color.groups?.includes(+brushedColorGroups[0].id)
                    )) ||
                [];
        }
        return matchingConstrColors;
    }

    private handleWindowCoreSide(
        profileColors: IccProfileSideColors,
        matchingConstrColors: IccConstructColor[],
        element: ColoredElements,
        profile?: Profile,
    ) {
        if (profileColors.outer?.colorId && profileColors.inner?.colorId) {
            const outerColor = this.getColorWithMatchingConstrColors(
                this.colors[profileColors.outer.colorId],
                this.configurationsService.conf.Current,
                {
                    element,
                    side: 'outer',
                    profile,
                }
            );
            const innerColor = this.getColorWithMatchingConstrColors(
                this.colors[profileColors.inner.colorId],
                this.configurationsService.conf.Current,
                {
                    element,
                    side: 'inner',
                    profile,
                }
            );
            if (element === 'profile') {
                element = 'frame';
            }
            const commonCoreColorsIds = outerColor.coreColors.filter((x) => {
                if (innerColor.id === outerColor.id) {
                    return (
                        outerColor.constructColors[x].some((combination) =>
                            combination.sides?.includes(element === 'frame' ? Side.FB : Side.SB)
                        ) &&
                        innerColor.constructColors[x]?.some((combination) =>
                            combination.sides?.includes(element === 'frame' ? Side.FB : Side.SB)
                        )
                    );
                } else if (outerColor.isCore && !innerColor.isCore) {
                    return (
                        outerColor.constructColors[x].some((combination) =>
                            combination.sides?.includes(element === 'frame' ? Side.FC : Side.SC)
                        ) &&
                        innerColor.constructColors[x]?.some((combination) =>
                            combination.sides?.includes(element === 'frame' ? Side.FI : Side.SI)
                        )
                    );
                } else if (!outerColor.isCore && innerColor.isCore) {
                    return (
                        outerColor.constructColors[x].some((combination) =>
                            combination.sides?.includes(element === 'frame' ? Side.FO : Side.SO)
                        ) &&
                        innerColor.constructColors[x]?.some((combination) =>
                            combination.sides?.includes(element === 'frame' ? Side.FC : Side.SC)
                        )
                    );
                } else {
                    return (
                        outerColor.constructColors[x].some((combination) =>
                            combination.sides?.includes(element === 'frame' ? Side.FDo : Side.SDo)
                        ) &&
                        innerColor.constructColors[x]?.some((combination) =>
                            combination.sides?.includes(element === 'frame' ? Side.FDi : Side.SDi)
                        )
                    );
                }
            });
            matchingConstrColors = matchingConstrColors.filter((p) =>
                commonCoreColorsIds.includes(p.coreColorId)
            ).sort((a, b) => {
                if (outerColor.defaultCoreColorId === a.colorId) {
                    return -1;
                }
                if (outerColor.defaultCoreColorId === b.colorId) {
                    return 1;
                }
                return 0;
            });
        }
        return matchingConstrColors;
    }

    public getColorWithMatchingConstrColors( color: IccColor, conf: WindowActiveConfiguration, {element, side = 'outer', profile, accessory }: {
        element: ColoredElements,
        side?: ProfileColoredSides,
        profile?: Profile,
        accessory?: IccAccessoryAccessory
    }): IccColor {
        const sides = this.getColorSides(element, side);
        let colorGroups: IccColorGroup[] = [];
        if (element === 'profile') {
            colorGroups = this.getColorGroupsForProfile(conf, sides, profile);
        } else if (element === 'accessory') {
            colorGroups = this.getColorGroupsForAccessory(conf, accessory);
        } else {
            colorGroups = this.getColorGroupsForWindow(conf, sides);
        }

        const newConstructColors: IccColor['constructColors'] = {};
        const newCoreColors: IccColor['coreColors'] = [];

        color.coreColors.forEach((coreColorId) => {
            let matchingConstrColors = [];
            if (element === 'profile') {
                matchingConstrColors = this.getMatchingProfileConstructColors(
                    conf,
                    colorGroups,
                    sides,
                    color.constructColors[coreColorId]?.map((x) => x.id)
                );
            } else {
                matchingConstrColors = this.getMatchingWindowConstructColors(
                    conf,
                    colorGroups,
                    sides,
                    color.constructColors[coreColorId]?.map((x) => x.id)
                );
            }
            if (matchingConstrColors.length) {
                newCoreColors.push(coreColorId);
                newConstructColors[coreColorId] = matchingConstrColors.map((x) => ({
                    id: x.id,
                    sides: x.sides,
                    type: x.type,
                }));
            }
        });

        return {
            ...color,
            coreColors: newCoreColors,
            constructColors: newConstructColors,
        };
    }

    private handleWindowInnerSide(
        profileColors: IccProfileSideColors,
        conf: WindowActiveConfiguration,
        element: ColoredElements,
        matchingConstrColors: IccConstructColor[],
        profile?: Profile,
    ) {
        // jeśli jest wybrana nakładka to nie ma wyboru koloru zewnętrznego,
        // więc kolor wewnętrzny nie może od niego zależeć
        if (profileColors.outer?.id && !conf.HasAlushell) {
            const outerColor = this.getColorWithMatchingConstrColors(
                this.colors[profileColors.outer.colorId],
                this.configurationsService.conf.Current,
                {
                    element,
                    side: 'outer',
                    profile,
                }
            );
            if (element === 'profile') {
                element = 'frame';
            }

            const onlyBilateral = element === 'frame' && conf.type === 'door' && this.onlyDoubleSidedFrameColor(conf);
            matchingConstrColors = matchingConstrColors.filter((p) => {
                const sides: Side[] = [];
                outerColor.coreColors.forEach((coreColorId) => {
                    if (p.coreColorId === coreColorId) {
                        if (outerColor?.isCore) {
                            sides.push(element === 'frame' ? Side.FI : Side.SI);
                        }
                        outerColor.constructColors[coreColorId]?.forEach((combination) => {
                            if (
                                p.colorId === outerColor.id &&
                                combination.sides?.includes(element === 'frame' ? Side.FB : Side.SB)
                            ) {
                                sides.push(element === 'frame' ? Side.FB : Side.SB);
                            }
                            if (
                                !onlyBilateral &&
                                p.colorId !== outerColor.id &&
                                combination.sides?.includes(
                                    element === 'frame' ? Side.FDo : Side.SDo
                                )
                            ) {
                                sides.push(element === 'frame' ? Side.FDi : Side.SDi);
                            }
                            if (
                                !onlyBilateral &&
                                combination.sides?.includes(element === 'frame' ? Side.FO : Side.SO)
                            ) {
                                sides.push(element === 'frame' ? Side.FC : Side.SC);
                            }
                        });
                    }
                });
                return (
                    sides.length &&
                    sides.some((s) => p.sides?.includes(s)) &&
                    outerColor.coreColors.includes(p.coreColorId)
                );
            });
        }
        return matchingConstrColors;
    }

    private handleWindowOuterSide(
        conf: WindowActiveConfiguration,
        matchingConstrColors: IccConstructColor[],
        profileColors: IccProfileSideColors
    ) {
        let filteredColors: IccConstructColor[] = [];
        if (conf.System.default_outer_colors_under_alushell === 'white') {
            filteredColors = matchingConstrColors.filter((p) => p.type === 'white' && p.isCore);

            if (!filteredColors.length && profileColors.inner?.id) {
                filteredColors = matchingConstrColors.filter(
                    (p) => p.id === profileColors.inner?.id
                );
            }
        } else if (
            conf.System.default_outer_colors_under_alushell === 'bilateral' &&
            profileColors.inner?.id
        ) {
            filteredColors = matchingConstrColors.filter((p) => p.id === profileColors.inner?.id);
        }
        if (filteredColors.length) {
            matchingConstrColors = filteredColors;
        }
        return matchingConstrColors;
    }

    private getMatchingWindowConstructColors(
        conf: WindowActiveConfiguration,
        colorGroups: IccColorGroup[],
        sides: Side[],
        ids?: number[]
    ) {
        return this.constructColors.filter(
            (p) =>
                (ids ? ids.includes(p.id) : true) &&
                p.systems?.map(Number)?.includes(+conf.System.id) &&
                (colorGroups.some((x) => p.groups?.map(Number).includes(+x.id)) || p.isCore) &&
                p.sides?.some((x) => sides.includes(x as Side)) &&
                (!p.wood_type || (conf.Wood?.id && +p.wood_type === +conf.Wood.id))
        );
    }

    sortConstrColors(constrColors: IccConstructColor[], colorGroups: IccColorGroup[]) {
        const colorGroupsIds = colorGroups.map((x) => x.id);
        constrColors.sort((a, b) => {
            if (isUndefined(a.groups) || a.groups === null) {
                return 1;
            } else if (isUndefined(b.groups) || b.groups === null) {
                return -1;
            }

            let pos = 0;
            let x = 0;
            let compareE1 = 9999999;
            for (; x < a.groups.length; ++x) {
                pos = colorGroupsIds.indexOf(String(a.groups[x]));
                if (pos > -1 && compareE1 > pos) {
                    compareE1 = pos;
                }
            }
            let compareE2 = 9999999;
            for (x = 0; x < b.groups.length; ++x) {
                pos = colorGroupsIds.indexOf(String(b.groups[x]));
                if (pos > -1 && compareE2 > pos) {
                    compareE2 = pos;
                }
            }
            if (compareE1 < compareE2) {
                return -1;
            } else if (compareE1 > compareE2) {
                return 1;
            } else {
                return Number(a.order) - Number(b.order);
            }
        });
    }

    getFilling(activeSash: ActiveSash, side: ProfileColoredSides | ColoredSides = 'outer') {
        let filling = activeSash.glazing;
        if (side === 'inner' && activeSash.panelInner?.id) {
            filling = activeSash.panelInner;
        }
        return filling;
    }

    // eslint-disable-next-line max-statements
    getMatchingFillingColorsFor(
        conf: WindowActiveConfiguration,
        side: ProfileColoredSides | ColoredSides,
        sash: ColoredSashes,
        alt = false,
        forDefault = false
    ) {
        if (side !== 'outer' && side !== 'inner') {
            return [];
        }
        const activeSash = isObject(sash)
            ? sash
            : this.getColoredSash(conf, sash ?? 'activeDoorSash');
        if (!activeSash) {
            return [];
        }
        const filling = this.getFilling(activeSash, side);
        const passiveDoorSash = this.isPassiveDoorSash(activeSash, conf, sash);
        const requiredColorGroups = this.getColorGroupsForFilling(conf, filling, alt, passiveDoorSash);

        let matchingColors = Object.values(this.colors).filter((color) =>
            requiredColorGroups?.some((x) => color.colorGroups.includes(+x.id))
            && color.systems?.includes(+conf.System.id)
        );

        const onlyBilateral = (
            this.isDoubleSidedColorAvailable(conf) &&
            (!conf.System.door_type || activeSash?.panelType !== 'Mixed')
        );

        const outerColor = this.getElementColor(conf, {
            element: 'filling',
            side: 'outer',
            sash: activeSash,
            alt,
        });

        if (side === 'inner' && onlyBilateral && outerColor?.id) {
            matchingColors = matchingColors.filter((color) => color.id === outerColor.id);
        }

        matchingColors.sort((colorA, colorB) => Number(colorA.order) - Number(colorB.order))
        if (forDefault) {
            const globalModelColor: IccColor | null = this.getGeneralModelColor(
                conf,
                side,
                activeSash,
                alt,
                passiveDoorSash,
            );

            const defaultColors =
                (side === 'inner' &&
                    filling.default_inside_colors &&
                    core.parseJson(filling.default_inside_colors)) ||
                (filling.default_colors && core.parseJson(filling.default_colors));
            const defaultColorFromFilling =
                this.colors[
                    this.constructColors.find(
                        (x) => x.id === +defaultColors?.[alt ? 'second' : 'first']?.colorId
                    )?.colorId ?? 0
                ] ?? null;

            const selectedColor = this.getElementColor(conf, {
                element: 'filling',
                side,
                sash: activeSash,
                alt,
            });

            let defaultColor: IccColor | null = null;
            let hasDefaultGlobalColor = false;
            if (globalModelColor?.id && !selectedColor?.id) {
                defaultColor =
                    matchingColors.find((color) => color.id === globalModelColor.id) ?? null;
                if (defaultColor?.id) {
                    defaultColor = {
                        ...defaultColor,
                        isDefault: globalModelColor?.isDefault,
                    };
                    hasDefaultGlobalColor = true;
                }
            }

            const matchingColorsIncludeDefaultColorFromFilling = matchingColors.find(color => color.id === defaultColorFromFilling?.id);
            if (matchingColorsIncludeDefaultColorFromFilling?.id && !passiveDoorSash && defaultColorFromFilling && (!hasDefaultGlobalColor || globalModelColor?.isDefault)) {
                // put default color from filling on top of the list
                matchingColors = matchingColors.filter(
                    (color) => color.id !== defaultColorFromFilling.id
                );
                matchingColors.unshift(defaultColorFromFilling);
            } else if (!matchingColorsIncludeDefaultColorFromFilling && !passiveDoorSash && requiredColorGroups.length > 0 && defaultColor?.isDefault) {
                const matchingColorFromFirstRequiredGroup = matchingColors.find(x=> x.colorGroups.includes(+requiredColorGroups[0].id));
                if(matchingColorFromFirstRequiredGroup?.id) {
                    matchingColors = matchingColors.filter(
                        (color) => color.id !== matchingColorFromFirstRequiredGroup.id
                    );
                    matchingColors.unshift(matchingColorFromFirstRequiredGroup);
                }
            } else if (defaultColor) {
                matchingColors = [defaultColor];
            }
        }

        return matchingColors;
    }

    private isPassiveDoorSash(activeSash: ActiveSash | undefined, conf: WindowActiveConfiguration, sash: ColoredSashes | ActiveSash | undefined) {
        if (sash === 'passiveDoorSash') {
            return true;
        }
        const passiveDoorSashForIntSash = !activeSash?.type && conf.Sashes.find(s => s.intSashes.find(intSash => intSash.id === activeSash?.id));
        const passiveDoorSash = activeSash?.type?.passive || (passiveDoorSashForIntSash && passiveDoorSashForIntSash.type?.passive);
        return passiveDoorSash;
    }

    private getGeneralModelColor(
        conf: WindowActiveConfiguration,
        side: ColoredSides,
        activeSash: ActiveSash,
        alt: boolean,
        passiveDoorSash = false
    ) {
        let globalModelColors: Partial<IccSideColors> | null = null;
        if (DoorActiveConfiguration.is(conf)) {
            if (side === 'inner' && activeSash.panelInner?.id) {
                if (alt) {
                    globalModelColors = ((activeSash.type?.passive || passiveDoorSash)
                        ? conf.ModelOptions?.passiveInnerSelectedColorSecond
                        : conf.ModelOptions.innerSelectedColorSecond) ?? null;
                } else {
                    globalModelColors = ((activeSash.type?.passive || passiveDoorSash)
                        ? conf.ModelOptions?.passiveInnerSelectedColor
                        : conf.ModelOptions.innerSelectedColor) ?? null;
                }
            } else {
                if (alt) {
                    globalModelColors = ((activeSash.type?.passive || passiveDoorSash)
                        ? conf.ModelOptions.passiveSelectedColorSecond
                        : conf.ModelOptions.selectedColorSecond) ?? null;
                } else {
                    globalModelColors = ((activeSash.type?.passive || passiveDoorSash)
                        ? conf.ModelOptions.passiveSelectedColor
                        : conf.ModelOptions.selectedColor) ?? null;
                }
            }
        }
        const globalModelColor: IccColor | null = globalModelColors?.[side] ?? null;
        return globalModelColor;
    }

    getMatchingDoorPortalColorsFor(conf: WindowActiveConfiguration) {
        const matchingGroups = this.getColorGroupsForDoorPortal(conf);
        const result = Object.values(this.colors).filter((p) =>
            matchingGroups?.some((x) => p.colorGroups?.includes(+x.id))
        );
        return result;
    }

    getMatchingThresholdColorsFor(conf: WindowActiveConfiguration) {
        const matchingGroups = this.getColorGroupsForThreshold(conf) || [];
        const result = Object.values(this.colors).filter((p) =>
            matchingGroups?.some((x) => p.colorGroups?.includes(+x.id))
        );
        return result;
    }

    getMatchingProfileColorsFor(
        conf: WindowActiveConfiguration,
        profile?: Profile,
        side?: ProfileColoredSides
    ) {
        const sides = this.getColorSides('profile', side ?? 'outer');
        const colorGroups = this.getColorGroupsForProfile(conf, sides, profile);
        let matchingConstrColors = this.getMatchingProfileConstructColors(conf, colorGroups, sides);

        const profileColors = profile?.selectedColor;
        if (!profileColors) {
            return [];
        }
        if (side === 'core') {
            matchingConstrColors = this.handleWindowCoreSide(
                profileColors,
                matchingConstrColors,
                'profile',
                profile,
            );
        } else if (side === 'inner') {
            matchingConstrColors = this.handleWindowInnerSide(
                profileColors,
                conf,
                'profile',
                matchingConstrColors,
                profile,
            );
        }
        if (side !== 'core') {
            this.sortConstrColors(matchingConstrColors, colorGroups);
        }

        return this.getMatchingColorsFromConstrColors(matchingConstrColors);
    }

    private getMatchingProfileConstructColors(
        conf: WindowActiveConfiguration,
        colorGroups: IccColorGroup[],
        sides: Side[],
        ids?: number[],
    ) {
        return this.constructColors.filter(
            (p) =>
                (ids ? ids.includes(p.id) : true) &&
                (p.systems?.map(Number)?.includes(+conf.System?.id) || !conf.System?.id) &&
                (colorGroups.some((x) => p.groups?.map(Number).includes(+x.id)) || p.isCore) &&
                p.sides?.some((x) => sides.includes(x as Side)) &&
                (!p.wood_type || (conf.Wood?.id && +p.wood_type === +conf.Wood.id) || !conf.Wood?.id)
        );
    }

    getMatchingMuntinColorsFor(
        conf: WindowActiveConfiguration,
        side: ProfileColoredSides | ColoredSides = 'outer'
    ) {
        const matchingGroups = this.getColorGroupsForMuntin(conf) || [];
        const result = Object.values(this.colors).filter((p) =>
            matchingGroups?.some((x) => p.colorGroups?.includes(+x.id))
        );

        if (side === 'outer') {
            const innerMuntinColor = this.getElementColor(conf, {
                element: 'muntin',
                side: 'inner',
            });
            const innerFrameColor = conf.Colors.frame.inner;
            const outerFrameColor = conf.Colors.frame.outer;
            if (innerFrameColor?.id === outerFrameColor?.id && !conf.HasAlushell) {
                return result.filter((p) => p.id === innerMuntinColor?.id);
            }
        }

        return result;
    }

    getMatchingAccessoryColorsFor(
        conf: WindowActiveConfiguration,
        accessory: IccAccessoryAccessory,
        side?: ProfileColoredSides
    ) {
        const matchingGroups = this.getColorGroupsForAccessory(conf, accessory);
        if (
            accessory.price_source !== 'confColors' ||
            typeof accessory.selectedColor === 'string'
        ) {
            return [];
        }
        if (side === 'core') {
            const accessoryColors = accessory?.selectedColor;
            if (accessoryColors?.outer?.colorId && accessoryColors?.inner?.colorId) {
                const outerColor = this.colors[accessoryColors.outer.colorId];
                const innerColor = this.colors[accessoryColors.inner.colorId];
                const commonCoreColorsIds = outerColor.coreColors.filter((x) =>
                    innerColor.coreColors.includes(x)
                );
                return commonCoreColorsIds.map((x) => this.colors[x]).filter((x) => x != null);
            }
        } else if (side === 'inner') {
            const accessoryColors = accessory?.selectedColor;
            if (accessoryColors?.outer?.colorId) {
                const outerColor = this.colors[accessoryColors.outer.colorId];
                return Object.values(this.colors).filter(
                    (p) =>
                        matchingGroups?.some((x) => p.colorGroups?.includes(+x.id)) &&
                        p.coreColors.some((x) => outerColor.coreColors.includes(x))
                );
            }
        }
        const result = Object.values(this.colors).filter((p) =>
            matchingGroups?.some((x) => p.colorGroups?.includes(+x.id))
        );
        return result;
    }

    getColorSides(element: ColoredElements, type: ProfileColoredSides) {
        if (element === 'profile') {
            element = 'frame';
        }
        let side: Side[] = [];
        if (type === 'outer') {
            side = [
                (element[0].toUpperCase() + '|O') as Side,
                (element[0].toUpperCase() + '|DO') as Side,
                (element[0].toUpperCase() + '|B') as Side,
                (element[0].toUpperCase() + '|C') as Side,
            ];
        } else if (type === 'inner') {
            side = [
                (element[0].toUpperCase() + '|I') as Side,
                (element[0].toUpperCase() + '|DI') as Side,
                (element[0].toUpperCase() + '|B') as Side,
                (element[0].toUpperCase() + '|C') as Side,
            ];
        } else if (type === 'alushell') {
            side = [(element[0].toUpperCase() + '|A') as Side];
        } else if (type === 'core') {
            side = [(element[0].toUpperCase() + '|C') as Side];
        }

        return side;
    }

    getColorGroupsForElement(
        conf = this.configurationsService.conf.Current,
        colorElement: ColorElement
    ) {
        if (
            colorElement.element === 'frame' ||
            colorElement.element === 'sash' ||
            colorElement.element === 'lipping'
        ) {
            const element =
                colorElement.element === 'lipping' && conf.System.lipping_color_based_on_frame_color
                    ? 'frame'
                    : colorElement.element;
            const sides =
                (colorElement.side && this.getColorSides(element, colorElement.side)) || [];
            return this.getColorGroupsForWindow(conf, sides);
        }
        if (colorElement.element === 'filling') {
            const activeSash = isObject(colorElement.sash)
                ? colorElement.sash
                : this.getColoredSash(conf, colorElement.sash ?? 'activeDoorSash');
            if (!activeSash) {
                return [];
            }
            const filling = this.getFilling(activeSash, colorElement.side);
            const passiveDoorSash = this.isPassiveDoorSash(activeSash, conf, colorElement.sash);
            return this.getColorGroupsForFilling(conf, filling, colorElement.alt, passiveDoorSash);
        }
        if (colorElement.element === 'doorPortal') {
            return this.getColorGroupsForDoorPortal(conf);
        }
        if (colorElement.element === 'threshold') {
            return this.getColorGroupsForThreshold(conf);
        }
        if (colorElement.element === 'profile' && typeof colorElement.profile === 'object') {
            const sides =
                (colorElement.side && this.getColorSides('profile', colorElement.side)) || [];
            return this.getColorGroupsForProfile(conf, sides, colorElement.profile);
        }
        if (colorElement.element === 'muntin') {
            return this.getColorGroupsForMuntin(conf);
        }
        return [];
    }

    getColorGroupsForWindow(conf = this.configurationsService.conf.Current, side: Side[] = []) {
        const windowColorGroups = (
            this.configuratorsDataService.data.windowColorGroups || []
        ).filter((el) => {
            let val =
                isArray(el.systems) &&
                el.systems?.indexOf(conf.System.id) > -1 &&
                el.target?.indexOf('show') > -1 &&
                (conf.System.type !== 'wood' ||
                    (isArray(el.woodTypes) &&
                        conf.Wood &&
                        el.woodTypes.indexOf(conf.Wood.id) > -1));

            const groupSides = el.sides || [];
            if (groupSides.length) {
                val = val && side.some((s) => groupSides.includes(s));
            }

            return val;
        });
        return windowColorGroups;
    }

    private filterColorGroupByDoorColorRestrictions(conf: WindowActiveConfiguration, passiveDoorSash = false) {
        const sashHeight = this.colorRestrictionService.getSashHeightInRebateBasedOnConstructionHeight(
            conf
        );
        const sashWidth = this.colorRestrictionService.getSashWidthInRebateBasedOnConstructionWidth(
            conf, passiveDoorSash
        );
        return (p: IccColorGroup) =>
            this.colorRestrictionService.isColorGroupNotRestricted(p) ||
            (this.colorRestrictionService.isColorGroupBetweenMinAndMaxMetalSheetWidth(
                p,
                sashWidth
            ) &&
                this.colorRestrictionService.isColorGroupBetweenMinAndMaxMetalSheetHeight(
                    p,
                    sashHeight
                ));
    }

    getFilteredColorGroups(filterGroup: (group: IccColorGroup) => boolean) {
        return (this.configuratorsDataService.data.windowColorGroups || []).filter(filterGroup);
    }

    getColorGroupsForFilling(
        conf: WindowActiveConfiguration,
        filling: Partial<IccFilling>,
        alt = false,
        passiveDoorSash = false
    ) {
        const requiredColorGroupsIds =
            (alt ? filling.part_color_groups_ids : filling.color_groups_ids)?.map(Number) ?? [];
        const filterByColorRestrictions = conf.System?.door_type && this.filterColorGroupByDoorColorRestrictions(conf, passiveDoorSash);
        return this.getFilteredColorGroups((group) => {
            if (filterByColorRestrictions && !filterByColorRestrictions(group)) {
                return false;
            }
            return requiredColorGroupsIds.includes(+group.id);
        });
    }

    getColorGroupsForDoorPortal(conf: WindowActiveConfiguration) {
        if (conf.doorPortal && conf.doorPortal?.id) {
            const colorGroup = conf.doorPortal?.priceLevelColorGroups;
            const colorGroupOut = conf.doorPortal?.priceLevelColorGroupsOut;
            const portalId = conf.doorPortal?.id;
            if (
                this.profilesService.profilesPrices &&
                portalId &&
                this.profilesService.profilesPrices[portalId]
            ) {
                return this.getFilteredColorGroups(
                    (c) =>
                        (colorGroup || []).includes(Number(c.id)) ||
                        (colorGroupOut || []).includes(Number(c.id))
                );
            }
        }
        return [];
    }

    getColorGroupsForThreshold(conf: WindowActiveConfiguration) {
        let filter: (color?: IccColorGroup) => boolean = () => false;
        const thresholdId = this.profilesService.getUsedThresholdId(conf);
        if (
            this.profilesService.profilesPrices &&
            thresholdId &&
            this.profilesService.profilesPrices[thresholdId] &&
            this.profilesService.profilesPrices[thresholdId][
                WindowActiveConfiguration.is(conf) ? conf.System.id : 'default'
            ] &&
            this.profilesService.profilesPrices[thresholdId][
                WindowActiveConfiguration.is(conf) ? conf.System.id : 'default'
            ].threshold
        ) {
            filter = (group) =>
                group?.systems != null &&
                ((WindowActiveConfiguration.is(conf) && group.systems?.includes(conf.System.id)) ||
                    !WindowActiveConfiguration.is(conf)) &&
                group.target.includes('show') &&
                this.profilesService.profilesPrices[thresholdId][
                    WindowActiveConfiguration.is(conf) ? conf.System.id : 'default'
                ].threshold.some(
                    (o) =>
                        Number(o.colorGroup) === Number(group.id) ||
                        Number(o.colorGroupOut) === Number(group.id)
                );
        }
        return this.getFilteredColorGroups(filter);
    }

    getColorGroupsForProfile(conf: WindowActiveConfiguration, side: Side[] = [], profile?: Profile) {
        let filter: (color?: IccColorGroup) => boolean = () => false;
        if (profile) {
            filter = (group) =>
                group != null &&
                group.target?.includes('show_profile') &&
                (profile.priceLevelColorGroups?.some((g) => +g === +group.id) ||
                    profile.priceLevelColorGroupsOut?.some((g) => +g === +group.id)) &&
                side.some((s) =>  (group.sides || []).includes(s))
        }
        return this.getFilteredColorGroups(filter);
    }

    getColorGroupsForMuntin(conf: WindowActiveConfiguration) {
        let filter: (color?: IccColorGroup) => boolean = () => false;
        if (conf.MuntinsData?.type) {
            filter = (group) =>
                group != null &&
                conf.MuntinsData?.type.colorGroups?.some((g: number | string) => +g === +group.id);
        }
        return this.getFilteredColorGroups(filter);
    }

    getColorGroupsForAccessory(conf: WindowActiveConfiguration, accessory?: IccAccessoryAccessory) {
        let filter: (color?: IccColorGroup) => boolean = () => false;
        if (accessory) {
            filter = (group) =>
                group != null &&
                accessory.conf_color_groups_ids?.some((g: number | string) => +g === +group.id);
        }
        return this.getFilteredColorGroups(filter);
    }

    getBrushedAlushellColorGroups() {
        const filter: (color?: IccColorGroup) => boolean = (group) =>
            group?.brushed_alu_group === true;
        return this.getFilteredColorGroups(filter);
    }

    getElementColor(conf: WindowActiveConfiguration, colorElement: ColorElement): IccColor | null {
        if (colorElement.element === 'frame' || colorElement.element === 'sash') {
            return this.getWindowColor(conf, colorElement.element, colorElement.side);
        }

        if (colorElement.element === 'filling') {
            return this.getFillingColor(
                conf,
                colorElement.side,
                colorElement.sash,
                colorElement.alt,
                colorElement.intSash,
            );
        }

        if (colorElement.element === 'lipping') {
            return this.getLippingColor(conf, colorElement.side);
        }

        if (colorElement.element === 'doorPortal') {
            return conf.doorPortal?.selectedColor ?? null;
        }

        if (colorElement.element === 'threshold') {
            return conf.thresholdColor ?? null;
        }

        if (colorElement.element === 'profile' && typeof colorElement.profile === 'object') {
            return this.getProfileColor(conf, colorElement.profile, colorElement.side);
        }

        if (colorElement.element === 'muntin') {
            return this.getMuntinColor(conf, colorElement.side);
        }

        if (colorElement.element === 'accessory' && typeof colorElement.accessory === 'object') {
            return this.getAccessoryColor(conf, colorElement.accessory, colorElement.side);
        }

        return null;
    }

    getWindowColor(
        conf: WindowActiveConfiguration,
        element: 'frame' | 'sash',
        side?: ProfileColoredSides | ColoredSides
    ) {
        if (side === 'core' || side === 'alushell') {
            return conf.Colors[element][side] ?? null;
        } else {
            const constrColor = conf.Colors[element][side ?? 'outer'];
            if (constrColor?.colorId) {
                return {
                    ...this.colors[constrColor.colorId],
                    isDefault: constrColor.isDefault,
                };
            }
            return null;
        }
    }

    getElementConstrColor(
        conf: WindowActiveConfiguration,
        element: 'frame' | 'sash' | 'profile' | 'accessory' | 'filling',
        side?: 'outer' | 'inner',
        profile?: Profile,
        accessory?: IccAccessoryAccessory
    ) {
        if (element === 'frame' || element === 'sash') {
            return conf.Colors[element][side ?? 'outer'];
        }
        if (element === 'profile' && profile) {
            return profile.selectedColor?.[side ?? 'outer'];
        }
        if (element === 'accessory' && accessory) {
            return typeof accessory.selectedColor === 'object'
                ? accessory.selectedColor?.[side ?? 'outer']
                : null;
        }
    }

    getFillingColor(
        conf: WindowActiveConfiguration,
        side?: ProfileColoredSides | ColoredSides,
        sash?: ColoredSashes,
        alt = false,
        intSash = false
    ) {
        let activeSash = isObject(sash)
            ? sash
            : this.getColoredSash(conf, sash ?? 'activeDoorSash');

        if (!activeSash) {
            return null;
        }

        if (intSash) {
            activeSash = activeSash.intSashes.find(x => x.glazing?.type !== 'glazing') || activeSash.intSashes[0];
        }
        if (!activeSash) {
            return null;
        }

        const filling = this.getFilling(activeSash, side);

        if (side === 'outer') {
            if (alt) {
                return filling.selectedColorSecond?.outer?.id ? filling.selectedColorSecond?.outer : null;
            }

            return filling.selectedColor?.outer?.id ? filling.selectedColor?.outer : null;
        } else {
            if (alt) {
                return filling.selectedColorSecond?.inner?.id ? filling.selectedColorSecond?.inner : null;
            }

            return filling.selectedColor?.inner?.id ? filling.selectedColor?.inner : null;
        }

        return null;
    }

    getLippingColor(conf: WindowActiveConfiguration, side?: ProfileColoredSides | ColoredSides) {
        if (side === 'inner') {
            return conf.innerLippingColor ?? null;
        }

        return conf.lippingColor ?? null;
    }

    getProfileColor(
        conf: WindowActiveConfiguration,
        profile: Profile,
        side?: ProfileColoredSides | ColoredSides
    ) {
        if (side === 'core' || side === 'alushell') {
            return profile?.selectedColor?.[side] ?? null;
        } else {
            const constrColor = profile?.selectedColor?.[side ?? 'outer'];
            if (constrColor?.colorId) {
                return {
                    ...this.colors[constrColor.colorId],
                    isDefault: constrColor.isDefault,
                };
            }
        }
        return null;
    }

    getMuntinColor(conf: WindowActiveConfiguration, side?: ProfileColoredSides | ColoredSides) {
        if (side === 'inner') {
            return conf.MuntinsData?.color ?? null;
        }

        return conf.MuntinsData?.colorOut ?? null;
    }

    getAccessoryColor(
        conf: WindowActiveConfiguration,
        accessory: IccAccessoryAccessory,
        side?: ProfileColoredSides | ColoredSides
    ) {
        if (typeof accessory.selectedColor === 'string') {
            return null;
        }

        if (side === 'core' || side === 'alushell') {
            return accessory?.selectedColor?.[side] ?? null;
        } else {
            const constrColor = accessory?.selectedColor?.[side ?? 'outer'];
            if (constrColor?.colorId) {
                return {
                    ...this.colors[constrColor.colorId],
                    isDefault: constrColor.isDefault,
                };
            }
        }
        return null;
    }

    setElementColor(
        color: IccColor,
        isDefault = true,
        conf: WindowActiveConfiguration,
        colorElement: ColorElement
    ) {
        const oldElementValue = this.getElementColor(conf, colorElement);
        let changed = color && Number(oldElementValue?.id) !== Number(color?.id);
        if (colorElement.element === 'frame' || colorElement.element === 'sash') {
            changed = this.setWindowColor(
                color,
                isDefault,
                conf,
                {
                    element: colorElement.element,
                    side: colorElement.side ?? 'outer',
                },
                changed
            );
        }

        if (colorElement.element === 'filling') {
            if (colorElement.sash === 'window' || this.matchColoredSash(conf, colorElement.sash) === 'window') {
                this.setFillingsColorInWindowSashes(
                    conf,
                    color,
                    isDefault,
                    {
                        side: colorElement.side,
                        alt: colorElement.alt,
                    }
                )
            } else {
                this.setFillingColor(color, isDefault, conf, {
                    side: colorElement.side,
                    sash: colorElement.sash,
                    alt: colorElement.alt,
                });
            }
        }

        if (colorElement.element === 'lipping') {
            this.setLippingColor(color, isDefault, conf, colorElement.side);
        }

        if (colorElement.element === 'doorPortal') {
            this.setDoorPortalColor(conf, color, isDefault);
        }

        if (colorElement.element === 'threshold') {
            this.setThresholdColor(conf, color, isDefault);
        }

        if (colorElement.element === 'profile' && typeof colorElement.profile === 'object') {
            changed = this.setProfileColor(
                color,
                isDefault,
                conf,
                {
                    profile: colorElement.profile,
                    side: colorElement.side ?? 'outer',
                },
                changed
            );
        }

        if (colorElement.element === 'muntin') {
            this.setMuntinColor(color, isDefault, conf, colorElement.side);
        }

        if (colorElement.element === 'accessory' && typeof colorElement.accessory === 'object') {
            changed = this.setAccessoryColor(
                color,
                isDefault,
                conf,
                {
                    accessory: colorElement.accessory,
                    side: colorElement.side ?? 'outer',
                },
                changed
            );
        }

        if (changed) {
            if (
                (colorElement.element === 'frame' || colorElement.element === 'sash') &&
                colorElement.side === 'core'
            ) {
                this.updateConstrColorsOnCoreChange(conf, colorElement.element);
            }
            this.validChildElementsColors(
                conf,
                colorElement,
                {
                    ...color,
                    isDefault,
                },
                oldElementValue
            );
        }

        Object.assign(conf, this.validationService.valid(conf, 'colors'));
    }

    setWindowColor(
        color: IccColor,
        isDefault: boolean,
        conf: WindowActiveConfiguration,
        {
            element,
            side = 'outer',
        }: {
            element: 'frame' | 'sash';
            side: ProfileColoredSides;
        },
        changed = false
    ) {
        if (!color) {
            return false;
        }
        let windowChanged = false;
        if (side === 'core' || side === 'alushell') {
            if (Number(conf.Colors[element][side]?.id) !== Number(color.id)) {
                conf.Colors[element][side] = {
                    ...color,
                    isDefault,
                };
                this.configurationsService.conf.Default.Colors[element][side] = {
                    ...color,
                    isDefault,
                };
                windowChanged = true;
            }
        } else {
            const constrColor = this.getMatchingConstrColor(conf, color, conf.Colors[element], { element });
            if (constrColor && Number(conf.Colors[element][side]?.id) !== Number(constrColor.id)) {
                conf.Colors[element][side] = {
                    ...constrColor,
                    isDefault,
                };
                this.configurationsService.conf.Default.Colors[element][side] = {
                    ...constrColor,
                    isDefault,
                };
                windowChanged = true;
            }
        }
        this.eventBusService.post({
            key: 'setConstructionColor',
            value: {},
            conf,
        });

        if (this.config().IccConfig.Configurators.dependencies) {
            this.configurationsService.conf.Current.BlockedAccessories = [];

            this.eventBusService.post({ key: 'processDependencies', value: null });
        }

        return changed || windowChanged;
    }

    getMatchingConstrColor(
        conf: WindowActiveConfiguration,
        color: IccColor,
        profileColors: Partial<IccProfileSideColors>,
        {
            element = 'frame',
            side,
            profile,
            accessory,
        }: {
            element: 'frame' | 'sash' | 'profile' | 'accessory',
            side?: ProfileColoredSides,
            profile?: Profile,
            accessory?: IccAccessoryAccessory,
        }
    ) {
        let constrColorsIds: (string | number)[] = [];
        if (!color?.id) {
            return null;
        }
        const requiredSides: Side[] = this.getRequiredProfileColorSides(
            side,
            profileColors,
            element
        );
        color = this.getColorWithMatchingConstrColors(color, conf, { element, side, profile, accessory });

        if (profileColors.core?.id && color.constructColors[profileColors.core.id]) {
            constrColorsIds = (color.constructColors[profileColors.core.id] ?? [])
                .filter(
                    (el) => !requiredSides.length || requiredSides.some((s) => el.sides.includes(s))
                )
                .map((el) => el.id);
        }
        if (!constrColorsIds.length) {
            for (const coreColorId of color.coreColors) {
                if (color.constructColors[coreColorId]) {
                    constrColorsIds = (color.constructColors[coreColorId] ?? [])
                        .filter(
                            (el) =>
                                !requiredSides.length ||
                                requiredSides.some((s) => el.sides.includes(s))
                        )
                        .map((el) => el.id);
                    if (constrColorsIds.length) {
                        break;
                    }
                }
            }
        }
        return this.constructColors.find((c) => constrColorsIds.includes(c.id));
    }

    private getRequiredProfileColorSides(
        side: string | undefined,
        profileColors: Partial<IccProfileSideColors>,
        element: string
    ) {
        if (element === 'profile' || element === 'accessory') {
            element = 'frame';
        }
        const requiredSides: Side[] = [];
        if (side === 'outer') {
            if (profileColors.inner?.id) {
                if (profileColors.inner?.sides?.includes(Side.FC)) {
                    requiredSides.push(element === 'frame' ? Side.FO : Side.SO);
                } else if (profileColors?.inner?.colorId === profileColors?.outer?.colorId) {
                    requiredSides.push(element === 'frame' ? Side.FB : Side.SB);
                } else if (
                    profileColors?.inner?.sides?.includes(element === 'frame' ? Side.FI : Side.SI)
                ) {
                    requiredSides.push(element === 'frame' ? Side.FC : Side.SC);
                }
                if (profileColors?.inner?.colorId !== profileColors?.outer?.colorId) {
                    requiredSides.push(element === 'frame' ? Side.FDo : Side.SDo);
                }
            }
        } else if (side === 'inner') {
            if (profileColors.outer?.id) {
                if (profileColors.outer?.sides?.includes(Side.FC)) {
                    requiredSides.push(element === 'frame' ? Side.FI : Side.SI);
                } else if (profileColors?.inner?.colorId === profileColors?.outer?.colorId) {
                    requiredSides.push(element === 'frame' ? Side.FB : Side.SB);
                } else if (
                    profileColors?.outer?.sides?.includes(element === 'frame' ? Side.FO : Side.SO)
                ) {
                    requiredSides.push(element === 'frame' ? Side.FC : Side.SC);
                }
                if (profileColors?.inner?.colorId !== profileColors?.outer?.colorId) {
                    requiredSides.push(element === 'frame' ? Side.FDi : Side.SDi);
                }
            }
        }
        return requiredSides;
    }

    updateConstrColorsOnCoreChange(conf: WindowActiveConfiguration, element: 'frame' | 'sash') {
        const color = this.getElementColor(conf, { element, side: 'outer' });
        if (color) {
            this.setWindowColor(color, color.isDefault ?? true, conf, { element, side: 'outer' });
        }
        const innerColor = this.getElementColor(conf, { element, side: 'inner' });
        if (innerColor) {
            this.setWindowColor(innerColor, innerColor.isDefault ?? true, conf, {
                element,
                side: 'inner',
            });
        }
    }

    setFillingsColorInWindowSashes(
        conf: WindowActiveConfiguration,
        color: IccColor,
        isDefault: boolean,
        {
            side,
            alt = false,
        }: {
            side?: ProfileColoredSides | ColoredSides;
            alt?: boolean;
        }
    ) {
        const sashes = conf.Sashes.filter(this.getColoredSashFinder('window'));
        if (sashes.length) {
            sashes.forEach((sash) => {
                this.setFillingColor(color, isDefault, conf, {
                    side,
                    sash,
                    alt,
                });
            });
        }
    }

    // eslint-disable-next-line max-statements
    setFillingColor(
        color: IccColor,
        isDefault = true,
        conf: WindowActiveConfiguration,
        {
            side,
            sash,
            alt = false,
            internal = false,
        }: {
            side?: ProfileColoredSides | ColoredSides;
            sash?: ColoredSashes;
            alt?: boolean;
            internal?: boolean;
        }
    ) {
        const activeSash = isObject(sash)
            ? sash
            : this.getColoredSash(conf, sash ?? 'activeDoorSash');

        if (!activeSash) {
            return null;
        }
        const newValue = {
            ...color,
            isDefault,
        };

        const filling = this.getFilling(activeSash, side);
        if (!filling.c) {
            filling.c = Math.round(Math.random() * 1000000)
        }
        if (filling.type !== 'deco_panels' && filling.type !== 'door_panels' && filling.type !== 'pvc_panels') {
            return null;
        }

        if (side === 'outer') {
            if (alt) {
                if (filling.selectedColorSecond) {
                    filling.selectedColorSecond.outer = newValue;
                } else {
                    filling.selectedColorSecond = {
                        outer: newValue,
                        inner: null,
                    };
                }
            } else {
                if (filling.selectedColor) {
                    filling.selectedColor.outer = newValue;
                } else {
                    filling.selectedColor = {
                        outer: newValue,
                        inner: null,
                    };
                }
            }
        } else {
            if (alt) {
                if (filling.selectedColorSecond) {
                    filling.selectedColorSecond.inner = newValue;
                } else {
                    filling.selectedColorSecond = {
                        outer: null,
                        inner: newValue,
                    };
                }
            } else {
                if (filling.selectedColor) {
                    filling.selectedColor.inner = newValue;
                } else {
                    filling.selectedColor = {
                        outer: null,
                        inner: newValue,
                    };
                }
            }
        }

        if (activeSash.intSashes?.length) {
            activeSash.intSashes.forEach((field) => {
                this.setFillingColor(color, isDefault, conf, {
                    side,
                    sash: field,
                    alt,
                    internal: true,
                });
            });
        }

        if (!internal) {
            this.eventBusService.post({
                key: 'setFillingColor',
                value: {},
                conf,
            });
        }
    }

    setLippingColor(
        color: IccColor,
        isDefault = true,
        conf: WindowActiveConfiguration,
        side?: ProfileColoredSides | ColoredSides
    ) {
        if (side === 'inner') {
            conf.innerLippingColor = {
                ...color,
                isDefault,
            };
        } else {
            conf.lippingColor = {
                ...color,
                isDefault,
            };
        }

        this.eventBusService.post({
            key: 'setLippingColor',
            value: {},
            conf,
        });
    }

    setThresholdColor(conf: WindowActiveConfiguration, color: IccColor, isDefault: boolean) {
        conf.thresholdColor = {
            ...color,
            isDefault,
        };
        this.eventBusService.post({
            key: 'setThresholdColor',
            value: {},
            conf,
        });
    }

    setDoorPortalColor(conf: WindowActiveConfiguration, color: IccColor, isDefault: boolean) {
        if (conf.doorPortal) {
            conf.doorPortal.selectedColor = {
                ...color,
                isDefault,
            };

            this.eventBusService.post({
                key: 'setDoorPortalColor',
                value: {},
                conf,
            });
        }
    }

    setProfileColor(
        color: IccColor,
        isDefault: boolean,
        conf: WindowActiveConfiguration,
        {
            profile,
            side,
        }: {
            profile: Profile;
            side: ProfileColoredSides;
        },
        changed = false
    ) {
        if (!color || !profile?.selectedColor) {
            return false;
        }
        let profileChanged = false;
        if (side === 'core' || side === 'alushell') {
            if (Number(profile.selectedColor[side]?.id) !== Number(color.id)) {
                profile.selectedColor[side] = {
                    ...color,
                    isDefault,
                };
                profileChanged = true;
            }
        } else {
            const constrColor = this.getMatchingConstrColor(conf, color, profile.selectedColor, { element: 'profile', profile });
            if (constrColor && Number(profile.selectedColor[side]?.id) !== Number(constrColor.id)) {
                profile.selectedColor[side] = {
                    ...constrColor,
                    isDefault,
                };
                profileChanged = true;
            }
        }
        this.eventBusService.post({
            key: 'setProfileColor',
            value: {},
            conf,
        });
        return changed || profileChanged;
    }

    setMuntinColor(
        color: IccColor,
        isDefault = true,
        conf: WindowActiveConfiguration,
        side?: ProfileColoredSides | ColoredSides
    ) {
        if (side === 'inner') {
            conf.MuntinsData.color = {
                ...color,
                isDefault,
            };

            this.eventBusService.post({
                key: 'setMuntinColor',
                value: {},
                conf,
            });
            return;
        }

        conf.MuntinsData.colorOut = {
            ...color,
            isDefault,
        };

        this.eventBusService.post({
            key: 'setMuntinColor',
            value: {},
            conf,
        });
    }

    setAccessoryColor(
        color: IccColor,
        isDefault: boolean,
        conf: WindowActiveConfiguration,
        {
            accessory,
            side,
        }: {
            accessory: IccAccessoryAccessory;
            side: ProfileColoredSides;
        },
        changed = false
    ) {
        if (!color || !accessory?.selectedColor || typeof accessory?.selectedColor !== 'object') {
            return false;
        }
        let accessoryChanged = false;
        if (side === 'core' || side === 'alushell') {
            if (Number(accessory.selectedColor[side]?.id) !== Number(color.id)) {
                accessory.selectedColor[side] = {
                    ...color,
                    isDefault,
                };
                accessoryChanged = true;
            }
        } else {
            const constrColor = this.getMatchingConstrColor(
                conf,
                color,
                accessory.selectedColor,
                {
                    element: 'accessory',
                    accessory,
                },
            );
            if (
                constrColor &&
                Number(accessory.selectedColor[side]?.id) !== Number(constrColor.id)
            ) {
                accessory.selectedColor[side] = {
                    ...constrColor,
                    isDefault,
                };
                accessoryChanged = true;
            }
        }
        this.eventBusService.post({
            key: 'setAccessoryColor',
            value: {},
            conf,
        });
        return changed || accessoryChanged;
    }

    getColoredSash(conf: WindowActiveConfiguration, sashType: Exclude<ColoredSashes, ActiveSash>) {
        return conf.Sashes.find(this.getColoredSashFinder(sashType));
    }

    matchColoredSash(
        conf: WindowActiveConfiguration,
        activeSash?: ColoredSashes | null
    ): Exclude<ColoredSashes, ActiveSash> | null {
        if (!activeSash) {
            return null;
        }
        if (typeof activeSash === 'string') {
            return activeSash;
        }
        let sash: ActiveSash | null = null;
        if (!activeSash.type?.type && activeSash?.parentId != null) {
            sash = conf.Sashes.find((s) => s.id === activeSash.parentId) ?? null;
        } else {
            sash = activeSash;
        }
        if (!sash) {
            return null;
        }
        if (sash.type?.type != null && ['DRA', 'DOA'].includes(sash.type.type)) {
            return 'activeDoorSash';
        } else if (sash.type?.type != null && ['DRP', 'DOP'].includes(sash.type.type)) {
            return 'passiveDoorSash';
        } else {
            return 'window';
        }
    }

    getAllColoredSashes(
        conf: WindowActiveConfiguration,
        sashType: Exclude<ColoredSashes, ActiveSash>
    ) {
        return conf.Sashes.filter(this.getColoredSashFinder(sashType));
    }

    validate() {
        const conf = this.configurationsService.conf.Current;
        if (conf.Colors) {
            this.validChildElementsColors(conf, null, null, null, true);
        }
    }

    setDefaultColors(conf: WindowActiveConfiguration, onLoad = false) {
        if (isObject(this.configurationsService.conf.Default?.Colors)) {
            conf.Colors = core.copy(this.configurationsService.conf.Default?.Colors);
        }
        this.validChildElementsColors(conf, null, null, null, onLoad);
    }

    // eslint-disable-next-line max-statements
    validChildElementsColors(
        conf: WindowActiveConfiguration,
        colorElement: ColorElement | null,
        parentElementValue?: IccColor | null,
        oldParentElementValue?: Partial<IccColor> | null,
        notChangeDefault = false
    ) {
        const notChangeDefaultOldValue = notChangeDefault;
        const childElements = this.getChildElement(conf, colorElement);
        for (const child of childElements) {
            if (
                (colorElement?.element === 'frame' ||
                    colorElement?.element === 'sash' ||
                    colorElement?.element === 'profile' ||
                    colorElement?.element === 'accessory') &&
                colorElement.element === child.element
            ) {
                if (colorElement.side === 'alushell' && child.side === 'outer') {
                    this.updateOuterColorsBelowAlushell(conf, child, colorElement);
                } else if (colorElement.side === 'inner' && child.side === 'outer') {
                    const outerColor = this.getElementColor(conf, child);
                    if (
                        conf.System.default_outer_colors_under_alushell === 'bilateral' &&
                        parentElementValue?.id &&
                        outerColor?.id !== parentElementValue?.id
                    ) {
                        this.setDefaultColorFor(conf, child, colorElement);
                    }
                } else if (colorElement.side === 'outer' && child.side === 'inner') {
                    const innerColor = this.getElementColor(conf, child);

                    if (
                        !innerColor?.id ||
                        (!(
                            innerColor.isCore &&
                            innerColor.constructColors?.[innerColor?.id]?.some(
                                (el) => el.type === 'white'
                            )
                        ) &&
                            oldParentElementValue?.id === innerColor?.id &&
                            this.hasBilateralColor(innerColor, child.element) &&
                            innerColor.isDefault !== false &&
                            !notChangeDefault) ||
                        (parentElementValue &&
                            this.getCommonCoreColors(innerColor, parentElementValue).length === 0) ||
                        (child.element === 'frame' && conf.type === 'door' && oldParentElementValue?.id === innerColor?.id &&
                            this.hasDecoFillings(conf) &&
                            !notChangeDefault
                        ) ||
                        (child.element === 'frame' && conf.type === 'door' && parentElementValue?.id !== innerColor?.id &&
                            this.onlyDoubleSidedFrameColor(conf)
                        )
                    ) {
                        this.setDefaultColorFor(conf, child, colorElement);
                        continue;
                    }
                    notChangeDefault = true;
                } else if (
                    colorElement.side === 'core' &&
                    (child.side === 'outer' || child.side === 'inner')
                ) {
                    const constrColor = this.getElementConstrColor(conf, child.element, child.side);
                    if (Number(constrColor?.coreColorId) !== Number(parentElementValue?.id)) {
                        this.setDefaultColorFor(conf, child);
                    }
                    continue;
                }
            } else if (
                colorElement?.element === 'sash' &&
                child.element === 'frame' &&
                colorElement.side === child.side
            ) {
                const elementColor = this.getElementColor(conf, child);

                if (
                    !conf.ColorsSashExt &&
                    parentElementValue?.id !== elementColor?.id
                ) {
                    this.setDefaultColorFor(conf, child);
                    continue;
                }
            } else if (
                colorElement?.element === 'filling' &&
                child.element === 'sash' &&
                colorElement.side === child.side
            ) {
                this.setDefaultColorFor(conf, child);
                continue;
            } else if (
                colorElement?.element === 'filling' &&
                child.element === 'frame' &&
                colorElement.side === child.side &&
                !conf.System.separate_frame_and_sash_color
            ) {
                this.setDefaultColorFor(conf, child);
                continue;
            } else if (
                colorElement?.element === 'filling' &&
                child.element === 'frame' &&
                colorElement.side === child.side &&
                child.side === 'inner' &&
                parentElementValue?.isDefault !== false
            ) {
                continue;
            } else if (
                colorElement?.element === 'filling' &&
                (
                    colorElement?.sash === 'passiveDoorSash' ||
                    this.isPassiveDoorSash(undefined, conf, colorElement.sash)
                ) &&
                child.element === 'filling' &&
                child.sash === 'passiveDoorSash' &&
                colorElement.side !== child.side &&
                child.side === 'inner'
            ) {
                const innerActiveColor = this.getElementColor(conf, {element: 'filling', 'side': 'inner', sash: 'activeDoorSash'});
                const outerActiveColor = this.getElementColor(conf, {element: 'filling', 'side': 'outer', sash: 'activeDoorSash'});
                const notMapFromOuter = outerActiveColor?.id !== innerActiveColor?.id && !this.hasDecoFillings(conf);
                const elementColor = this.getElementColor(conf, child);
                if(!notMapFromOuter && innerActiveColor?.isDefault !== false && (!elementColor || elementColor?.isDefault !== false)) {
                    this.setDefaultColorFor(conf, child, colorElement);
                }
                if (!notMapFromOuter && elementColor?.id) {
                    continue;
                }
            } else if(
                child.element === 'filling' &&
                (
                    child.sash === 'activeDoorSash' ||
                    child.sash === 'passiveDoorSash'
                )
            ) {
                const elementColor = this.getElementColor(conf, child);
                if(!notChangeDefault && elementColor?.isDefault !== false && !parentElementValue?.isDefault) {
                    if(child.sash === 'passiveDoorSash') {
                        this.setDefaultColorFor(conf, child, colorElement);
                        continue;
                    } else {
                        this.setDefaultColorFor(conf, child);
                    }
                }
            } else if (child.element === 'profile' && child.side === 'inner' && colorElement?.element === 'frame') {
                const outerProfileColor = this.getElementColor(conf, {...child, side: 'outer'});
                const elementColor = this.getElementColor(conf, child);
                if (elementColor?.isDefault !== false && !outerProfileColor?.isDefault) {
                    continue;
                }
            }
            this.validateElementColor(conf, child, notChangeDefault, colorElement);
            notChangeDefault = notChangeDefaultOldValue;
        }
    }

    validateElementColor(
        conf: WindowActiveConfiguration,
        colorElement: ColorElement,
        notChangeDefault = false,
        parent?: ColorElement | null,
        setDefaultColorIfNoParent = false,
    ) {
        const color = this.getElementColor(conf, colorElement);
        const matchingColors = this.getMatchingColorsFor(conf, colorElement);

        if ((colorElement.element === 'doorPortal' || colorElement.element === 'threshold') && !this.profilesService.loadedData) {
            return;
        }

        if (
            !color?.id ||
            (color.isDefault !== false && !notChangeDefault && (parent != null || setDefaultColorIfNoParent)) ||
            !matchingColors.map((el) => el.id).includes(color.id)
        ) {
            if (!parent?.intSash && colorElement.intSash) {
                this.setDefaultColorFor(conf, colorElement, parent);
            } else {
                this.setDefaultColorFor(conf, colorElement);
            }
        } else {
            this.validChildElementsColors(conf, colorElement, color, color, true);
        }
    }

    updateOuterColorsBelowAlushell(
        conf: WindowActiveConfiguration,
        child: ColorElement,
        colorElement: ColorElement
    ) {
        const outerColor = this.getElementColor(conf, child);
        const innerColor = this.getElementColor(conf, {
            ...child,
            side: 'inner',
        });

        if (
            !outerColor?.id ||
            (conf.System.default_outer_colors_under_alushell === 'white' &&
                !(
                    outerColor.isCore &&
                    outerColor.constructColors?.[outerColor?.id]?.some((el) => el.type === 'white')
                )) ||
            (conf.System.default_outer_colors_under_alushell === 'bilateral' &&
                innerColor?.id &&
                outerColor?.id !== innerColor?.id)
        ) {
            this.setDefaultColorFor(conf, child, colorElement);
        }
    }

    getCommonCoreColors(color1: Partial<IccColor>, color2: Partial<IccColor>) {
        const coreColors1 = color1.coreColors;
        const coreColors2 = color2.coreColors;
        return coreColors1?.filter((x) => coreColors2?.includes(x)) ?? [];
    }

    hasBilateralColor(
        color: Partial<IccColor>,
        element: 'frame' | 'sash' | 'lipping' | 'profile' | 'accessory' | 'filling',
        coreColorsIds?: number[]
    ) {
        let hasBilateralColor = false;
        let requiredSide = Side.FB;
        if (element === 'sash') {
            requiredSide = Side.SB;
        } else if (element === 'lipping') {
            requiredSide = Side.LB;
        }
        for (const coreColorId of color.coreColors ?? []) {
            if (!coreColorsIds || coreColorsIds.includes(coreColorId)) {
                for (const x of color.constructColors?.[coreColorId] ?? []) {
                    if (x.sides.includes(requiredSide)) {
                        hasBilateralColor = true;
                        break;
                    }
                }
            }
        }
        return hasBilateralColor;
    }

    getMappingGraph(conf: WindowActiveConfiguration): ColorMapping[] {
        return [
            {
                parent: { element: 'sash', side: 'alushell' },
                condition: () =>
                    WindowActiveConfiguration.is(conf) &&
                    !this.hasDecoFillings(conf) &&
                    conf.HasAlushell,
                children: [
                    {
                        element: 'sash',
                        side: 'outer',
                    },
                ],
            },
            {
                parent: null,
                condition: () =>
                    WindowActiveConfiguration.is(conf) && !this.hasDecoFillings(conf),
                children: [
                    {
                        element: 'sash',
                        side: 'outer',
                    },
                ],
            },
            {
                parent: null,
                condition: () =>
                    WindowActiveConfiguration.is(conf) &&
                    !this.hasDecoFillings(conf) &&
                    conf.HasAlushell,
                children: [
                    {
                        element: 'sash',
                        side: 'alushell',
                    },
                ],
            },
            {
                parent: null,
                condition: () => DoorActiveConfiguration.is(conf) && this.hasDecoFillings(conf),
                children: [
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'activeDoorSash',
                    }
                ],
            },
            {
                parent: null,
                condition: () => DoorActiveConfiguration.is(conf) && this.hasDecoPanelSecondColor(conf),
                children: [
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'activeDoorSash',
                        alt: true,
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'outer', sash: 'activeDoorSash' },
                condition: () => DoorActiveConfiguration.is(conf) && this.hasDecoFillings(conf),
                children: [
                    {
                        element: 'sash',
                        side: 'outer',
                    },
                    {
                        element: 'frame',
                        side: 'outer',
                    },
                ],
            },
            {
                parent: { element: 'sash', side: 'inner' },
                condition: () =>
                    WindowActiveConfiguration.is(conf) && !this.hasDecoFillings(conf),
                children: [
                    { element: 'frame', side: 'inner' },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'window',
                    },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'activeDoorSash',
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'outer', sash: 'activeDoorSash' },
                condition: () => DoorActiveConfiguration.is(conf),
                children: [
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'activeDoorSash',
                        intSash: true,
                    },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'activeDoorSash',
                    },
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'passiveDoorSash',
                    },
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'window',
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'inner', sash: 'activeDoorSash' },
                condition: () => DoorActiveConfiguration.is(conf),
                children: [
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'activeDoorSash',
                        intSash: true,
                    },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'passiveDoorSash',
                    },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'window',
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'inner', sash: 'activeDoorSash' },
                condition: () => DoorActiveConfiguration.is(conf) && this.hasDecoFillings(conf),
                children: [
                    {
                        element: 'sash',
                        side: 'inner',
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'outer', sash: 'passiveDoorSash' },
                condition: () => DoorActiveConfiguration.is(conf),
                children: [
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'passiveDoorSash',
                        intSash: true,
                    },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'passiveDoorSash',
                    },
                ],
            },

            {
                parent: { element: 'filling', side: 'inner', sash: 'activeDoorSash' },
                condition: () => DoorActiveConfiguration.is(conf) && this.hasDecoFillings(conf) && (!this.onlyDoubleSidedFrameColor(conf) || !conf.System.separate_frame_and_sash_color),
                children: [
                    {
                        element: 'frame',
                        side: 'inner',
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'outer', sash: 'activeDoorSash', alt: true },
                condition: () => DoorActiveConfiguration.is(conf),
                children: [
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'activeDoorSash',
                        intSash: true,
                        alt: true,
                    },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'activeDoorSash',
                        alt: true,
                    },
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'passiveDoorSash',
                        alt: true,
                    },
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'window',
                        alt: true,
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'inner', sash: 'activeDoorSash', alt: true },
                condition: () => DoorActiveConfiguration.is(conf),
                children: [
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'activeDoorSash',
                        intSash: true,
                        alt: true,
                    },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'passiveDoorSash',
                        alt: true,
                    },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'window',
                        alt: true,
                    },
                ],
            },

            {
                parent: { element: 'filling', side: 'inner', sash: 'passiveDoorSash' },
                condition: () => DoorActiveConfiguration.is(conf),
                children: [
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'passiveDoorSash',
                        intSash: true,
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'outer', sash: 'passiveDoorSash', alt: true },
                condition: () => DoorActiveConfiguration.is(conf),
                children: [
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'passiveDoorSash',
                        intSash: true,
                        alt: true,
                    },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'passiveDoorSash',
                        alt: true,
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'inner', sash: 'passiveDoorSash', alt: true },
                condition: () => DoorActiveConfiguration.is(conf),
                children: [
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'passiveDoorSash',
                        intSash: true,
                        alt: true,
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'outer', sash: 'window' },
                children: [
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'window',
                        intSash: true,
                    },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'window',
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'inner', sash: 'window' },
                children: [
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'window',
                        intSash: true,
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'outer', sash: 'window', alt: true },
                children: [
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'window',
                        intSash: true,
                        alt: true,
                    },
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'window',
                        alt: true,
                    },
                ],
            },
            {
                parent: { element: 'filling', side: 'inner', sash: 'window', alt: true },
                children: [
                    {
                        element: 'filling',
                        side: 'inner',
                        sash: 'window',
                        intSash: true,
                        alt: true,
                    },
                ],
            },
            {
                parent: { element: 'frame', side: 'outer' },
                children: [{ element: 'frame', side: 'inner' }],
                condition: () => DoorActiveConfiguration.is(conf) && this.hasDecoFillings(conf),
            },
            {
                parent: { element: 'sash', side: 'outer' },
                condition: () =>
                    WindowActiveConfiguration.is(conf) && !this.hasDecoFillings(conf),
                children: [
                    { element: 'frame', side: 'outer' },
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'window',
                    },
                    {
                        element: 'filling',
                        side: 'outer',
                        sash: 'activeDoorSash',
                    },
                ],
            },
            {
                parent: { element: 'sash', side: 'outer' },
                children: [{ element: 'sash', side: 'core' }],
            },
            {
                parent: { element: 'sash', side: 'outer' },
                children: [{ element: 'sash', side: 'inner' }],
                condition: () =>
                    WindowActiveConfiguration.is(conf) && !this.hasDecoFillings(conf) &&
                    !conf.HasAlushell,
            },
            {
                parent: { element: 'sash', side: 'inner' },
                children: [{ element: 'sash', side: 'outer' }],
                condition: () => WindowActiveConfiguration.is(conf) && conf.HasAlushell,
            },
            {
                parent: { element: 'sash', side: 'inner' },
                children: [{ element: 'sash', side: 'core' }],
            },
            {
                parent: { element: 'frame', side: 'inner' },
                children: [{ element: 'frame', side: 'outer' }],
                condition: () => WindowActiveConfiguration.is(conf) && conf.HasAlushell,
            },
            {
                parent: { element: 'sash', side: 'core' },
                condition: () =>
                    WindowActiveConfiguration.is(conf),
                children: [{ element: 'frame', side: 'core' }],
            },
            {
                parent: { element: 'sash', side: 'alushell' },
                condition: () =>
                    WindowActiveConfiguration.is(conf) && !this.hasDecoFillings(conf),
                children: [{ element: 'frame', side: 'alushell' }],
            },
            {
                parent: { element: 'frame', side: 'inner' },
                children: [{ element: 'threshold' }],
                condition: () =>
                    WindowActiveConfiguration.is(conf) &&
                    this.profilesService.getUsedThresholdId(conf) != null,
            },
            {
                parent: { element: 'frame', side: 'outer' },
                children: [{ element: 'profile', side: 'outer', profile: 'sideProfiles' }],
                condition: () =>
                    WindowActiveConfiguration.is(conf) &&
                    conf.System?.extension_color_based_on_frame_color,
            },
            {
                parent: { element: 'frame', side: 'inner' },
                children: [{ element: 'profile', side: 'inner', profile: 'sideProfiles' }],
                condition: () =>
                    WindowActiveConfiguration.is(conf) &&
                    conf.System?.extension_color_based_on_frame_color,
            },
            {
                parent: { element: 'frame', side: 'core' },
                children: [{ element: 'profile', side: 'core', profile: 'sideProfiles' }],
                condition: () =>
                    WindowActiveConfiguration.is(conf) &&
                    conf.System?.extension_color_based_on_frame_color,
            },
            {
                parent: { element: 'profile', side: 'outer' },
                children: [{ element: 'profile', side: 'inner' }],
                condition: (element) => WindowActiveConfiguration.is(conf) ||
                    typeof element?.profile === 'object' && element.profile.type === 'coupling',
            },
            {
                parent: { element: 'profile', side: 'outer' },
                children: [{ element: 'profile', side: 'core' }],
            },
            {
                parent: { element: 'profile', side: 'inner' },
                children: [{ element: 'profile', side: 'core' }],
            },
            {
                parent: { element: 'sash', side: 'inner' },
                children: [{ element: 'muntin', side: 'inner' }],
            },
            {
                parent: { element: 'sash', side: 'outer' },
                children: [{ element: 'muntin', side: 'outer' }],
            },
            {
                parent: { element: 'muntin', side: 'inner' },
                children: [{ element: 'muntin', side: 'outer' }],
            },
            {
                parent: { element: 'accessory', side: 'outer' },
                children: [{ element: 'accessory', side: 'inner' }],
                condition: (element) =>
                    typeof element?.accessory === 'object' && element.accessory.material === 'pvc',
            },
            {
                parent: { element: 'accessory', side: 'outer' },
                children: [{ element: 'accessory', side: 'core' }],
            },
            {
                parent: { element: 'accessory', side: 'inner' },
                children: [{ element: 'accessory', side: 'core' }],
            },
            {
                parent: { element: 'lipping', side: 'outer' },
                children: [{ element: 'lipping', side: 'inner' }],
                condition: () => DoorActiveConfiguration.is(conf),
            },
            {
                parent: { element: 'frame', side: 'outer' },
                children: [{ element: 'lipping', side: 'outer' }, { element: 'doorPortal' }],
            },
            {
                parent: { element: 'frame', side: 'inner' },
                children: [{ element: 'lipping', side: 'inner' }],
            },
        ];
    }

    getParentElement(
        conf: WindowActiveConfiguration,
        colorElement: ColorElement
    ): ColorElement | null {
        const mappings = this.getMappingGraph(conf);
        const parents: ColorElement[] = [];
        for (const mapping of mappings) {
            if (!mapping.parent) {
                continue;
            }
            if (mapping.condition && !mapping.condition(colorElement)) {
                continue;
            }
            for (const child of mapping.children) {
                if (
                    child.element === colorElement.element &&
                    // eslint-disable-next-line eqeqeq
                    child.side == colorElement.side &&
                    // eslint-disable-next-line eqeqeq
                    child.sash == this.matchColoredSash(conf, colorElement.sash) &&
                    ((!colorElement.alt && !child.alt) || (colorElement.alt && child.alt)) &&
                    (!child.intSash ||
                        (typeof colorElement.sash === 'object' &&
                            colorElement.sash.parentId != null))
                ) {
                    parents.push({
                        ...mapping.parent,
                        profile:
                            typeof colorElement.profile === 'object'
                                ? colorElement.profile
                                : mapping.parent.profile,
                        accessory:
                            typeof colorElement.accessory === 'object'
                                ? colorElement.accessory
                                : mapping.parent.accessory,
                    });
                }
            }
        }
        if (parents.length > 1) {
            logger.warn(
                'More than one parent found for element',
                colorElement.element,
                colorElement.side,
                colorElement.sash,
                colorElement.alt
            );
        }
        return parents[0] ?? null;
    }

    getChildElement(
        conf: WindowActiveConfiguration,
        colorElement?: ColorElement | null
    ): ColorElement[] {
        const mappings = this.getMappingGraph(conf);
        const children: ColorElement[] = [];
        for (const mapping of mappings) {
            if (mapping.condition && !mapping.condition(colorElement)) {
                continue;
            }
            if (
                (!mapping.parent && !colorElement?.element) ||
                (mapping.parent &&
                    // eslint-disable-next-line eqeqeq
                    mapping.parent.element == colorElement?.element &&
                    // eslint-disable-next-line eqeqeq
                    mapping.parent.side == colorElement?.side &&
                    // eslint-disable-next-line eqeqeq
                    mapping.parent.sash == this.matchColoredSash(conf, colorElement?.sash) &&
                    ((!colorElement?.alt && !mapping.parent.alt) ||
                        (colorElement?.alt && mapping.parent.alt)) &&
                    !colorElement?.intSash)
            ) {
                for (const child of mapping.children) {
                    if (
                        colorElement?.element === 'profile' &&
                        typeof colorElement?.profile === 'object' &&
                        child.element === 'profile'
                    ) {
                        children.push({
                            ...child,
                            profile: colorElement.profile,
                        });
                    } else if (child.profile === 'sideProfiles') {
                        children.push(...this.getSideProfilesChildren(conf, child));
                    } else if (
                        colorElement?.element === 'accessory' &&
                        typeof colorElement?.accessory === 'object' &&
                        child.element === 'accessory'
                    ) {
                        children.push({
                            ...child,
                            accessory: colorElement.accessory,
                        });
                    } else if (typeof colorElement?.sash === 'object') {
                        if (child.intSash) {
                            children.push(...this.getIntSashChildren(conf, colorElement, child));
                        } else if (child.sash === 'window' && this.matchColoredSash(conf, colorElement?.sash) === 'window') {
                            children.push({
                                ...child,
                                sash: colorElement.sash,
                            });
                        } else {
                            children.push(child);
                        }
                    } else if (colorElement?.sash === 'window') {
                        children.push(child);
                    } else {
                        children.push(child);
                    }
                }
            }
        }
        return children;
    }

    getSideProfilesChildren(conf: WindowActiveConfiguration, colorElement: ColorElement) {
        const children: ColorElement[] = [];
        for (const sideProfile of conf.SideProfiles) {
            const profile = this.profilesService.getProfile(sideProfile.profileId);

            children.push({
                ...colorElement,
                profile: {
                    ...profile,
                    selectedColor: sideProfile.color || undefined,
                },
            });
        }
        return children;
    }

    getIntSashChildren(conf: WindowActiveConfiguration, parent: ColorElement, child: ColorElement) {
        if (typeof parent.sash !== 'object' || parent.sash.parentId != null) {
            return [];
        }

        const children: ColorElement[] = [];
        const sash = parent.sash;
        for (const intSash of sash.intSashes ?? []) {
            children.push({
                ...child,
                sash: intSash,
            });
        }
        return children;
    }

    setDefaultColorFor(
        conf: WindowActiveConfiguration,
        colorElement: ColorElement,
        parentColorElement?: ColorElement | null
    ) {
        const defaultColor = this.getDefaultElementColor(conf, colorElement, parentColorElement);

        if (defaultColor) {
            this.setElementColor(defaultColor, colorElement.element === 'filling' && defaultColor.isDefault != null ? defaultColor.isDefault : true, conf, colorElement);
        } else {
            this.clearElementColor(conf, colorElement);
        }
    }

    getDefaultElementColor(
        conf: WindowActiveConfiguration,
        colorElement: ColorElement,
        parentElement?: ColorElement | null
    ) {
        parentElement = parentElement ?? this.getParentElement(conf, colorElement);
        let parentColor = parentElement && this.getElementColor(conf, parentElement);
        parentColor = parentElement && parentColor && this.getColorWithMatchingConstrColors(
            parentColor,
            conf,
            {
                element: parentElement.element,
                side: parentElement.side,
                profile: typeof parentElement.profile === 'object' ? parentElement.profile : undefined,
                accessory: typeof  parentElement.accessory === 'object' ? parentElement.accessory : undefined,
            }
        );
        const matchingColors = this.getMatchingColorsFor(conf, colorElement, true);
        const matchedParentColor = matchingColors.find((color) => color.id === parentColor?.id);

        if (matchedParentColor) {
            return matchedParentColor;
        }

        const constrColors =
            parentColor?.coreColors?.reduce<number[]>((ids, coreColorId) => {
                parentColor?.constructColors[coreColorId].forEach((el) => {
                    if (!ids.includes(el.id)) {
                        ids.push(el.id);
                    }
                });
                return ids;
            }, []) ?? [];
        const mappedColorsIds = this.colorMappingService.getColors(
            constrColors,
            'window',
            'window'
        );
        const mappedColors = matchingColors.filter((color) =>
            color.coreColors.some((coreColorId) =>
                color.constructColors[coreColorId].some((el) => mappedColorsIds.includes(el.id))
            )
        );

        if (mappedColors.length) {
            return mappedColors[0];
        }

        return matchingColors[0];
    }

    openModalColorPicker(conf: WindowActiveConfiguration, colorElement: ColorElement) {
        return this.modalService
            .open({
                pageComponent: ColorsPageComponent,
                resolve: {
                    colors: () => this.getMatchingColorsFor(conf, colorElement),
                    selectedColor: () => this.getElementColor(conf, colorElement),
                    colorGroups: () =>
                        colorElement.side !== 'core'
                            ? this.getColorGroupsForElement(conf, colorElement)
                            : [],
                },
            })
            .result.then((result: { color: IccColor; colorGroup?: number | 'none' }) => {
                if (result) {
                    this.setElementColor(result.color, false, conf, colorElement);
                }
                return result;
            });
    }

    setExtendedMode(colorsSashExt = false, conf = this.configurationsService.conf.Current) {
        conf.ColorsSashExt = colorsSashExt;
        if (!conf.ColorsSashExt) {
            this.validChildElementsColors(conf, { element: 'sash', side: 'outer' });
            this.validChildElementsColors(conf, { element: 'sash', side: 'inner' });
            this.validChildElementsColors(conf, { element: 'sash', side: 'alushell' });
            this.validChildElementsColors(conf, { element: 'sash', side: 'core' });
        }
        this.eventBusService.post({
            key: 'setColorsMode',
            value: {},
        });
    }

    getWhiteElementColor(conf: WindowActiveConfiguration, colorElement: ColorElement) {
        const matchingColors = this.getMatchingColorsFor(conf, colorElement);
        const mappedColors = matchingColors.filter((color) =>
            color.coreColors.some((coreColorId) =>
                color.constructColors[coreColorId].some((el) => el.type === 'white')
            )
        );

        if (mappedColors.length) {
            return mappedColors[0];
        }

        return null;
    }

    setWhiteColor(conf: WindowActiveConfiguration, colorElement: ColorElement) {
        const defaultColor = this.getWhiteElementColor(conf, colorElement);
        if (defaultColor) {
            this.setElementColor(defaultColor, true, conf, colorElement);
        }
    }

    setBilateralColor(conf: WindowActiveConfiguration, colorElement: ColorElement) {
        const outerColor = this.getElementColor(conf, {
            element: colorElement.element,
            side: 'outer',
            sash: colorElement.sash,
            profile: colorElement.profile,
            accessory: colorElement.accessory,
            alt: colorElement.alt,
        });
        const innerColor = this.getElementColor(conf, {
            element: colorElement.element,
            side: 'inner',
            sash: colorElement.sash,
            profile: colorElement.profile,
            accessory: colorElement.accessory,
            alt: colorElement.alt,
        });
        if (outerColor?.id && outerColor?.id !== innerColor?.id) {
            this.setElementColor(outerColor, true, conf, {
                element: colorElement.element,
                side: 'inner',
                sash: colorElement.sash,
                profile: colorElement.profile,
                accessory: colorElement.accessory,
                alt: colorElement.alt,
            });
        }
    }

    clearElementColor(conf: WindowActiveConfiguration, colorElement: ColorElement) {
        const oldElementValue = this.getElementColor(conf, colorElement);
        let changed = oldElementValue?.id != null;
        if (colorElement.element === 'frame' || colorElement.element === 'sash') {
            const windowChanged = this.clearWindowColor(
                conf,
                colorElement.element,
                colorElement.side
            );
            changed = changed || windowChanged;
        }

        if (colorElement.element === 'filling') {
            this.clearFillingColor(conf, colorElement.side, colorElement.sash, colorElement.alt);
        }

        if (colorElement.element === 'lipping') {
            this.clearLippingColor(conf, colorElement.side);
        }

        if (colorElement.element === 'doorPortal') {
            if (conf.doorPortal) {
                conf.doorPortal.selectedColor = null;
            }
        }

        if (colorElement.element === 'threshold') {
            conf.thresholdColor = null;
        }

        if (colorElement.element === 'muntin') {
            this.clearMuntinColor(conf, colorElement.side);
        }

        if (changed) {
            if (
                (colorElement.element === 'frame' || colorElement.element === 'sash') &&
                colorElement.side === 'core'
            ) {
                this.updateConstrColorsOnCoreChange(conf, colorElement.element);
            }
            this.validChildElementsColors(conf, colorElement, null, oldElementValue);
        }
        Object.assign(conf, this.validationService.valid(conf, 'colors'));

        if (colorElement.element !== 'profile') {
            this.eventBusService.post({
                key: 'setConstructionColor',
                value: {},
                conf,
            });
        }
    }

    clearWindowColor(
        conf: WindowActiveConfiguration,
        element: 'frame' | 'sash',
        side: ProfileColoredSides = 'outer'
    ) {
        let changed = false;
        if (conf.Colors[element][side]?.id != null) {
            conf.Colors[element][side] = null;
            changed = true;
        }
        return changed;
    }

    clearFillingColor(
        conf: WindowActiveConfiguration,
        side?: ProfileColoredSides | ColoredSides,
        sash?: ColoredSashes,
        alt = false
    ) {
        const activeSash = isObject(sash)
            ? sash
            : this.getColoredSash(conf, sash ?? 'activeDoorSash');

        if (!activeSash) {
            return null;
        }

        const filling = this.getFilling(activeSash, side);

        if (side === 'outer') {
            if (alt) {
                if (filling.selectedColorSecond) {
                    filling.selectedColorSecond.outer = null;
                } else {
                    filling.selectedColorSecond = {
                        outer: null,
                        inner: null,
                    };
                }
                return;
            }

            if (filling.selectedColor) {
                filling.selectedColor.outer = null;
            } else {
                filling.selectedColor = {
                    outer: null,
                    inner: null,
                };
            }
        } else {
            if (alt) {
                if (filling.selectedColorSecond) {
                    filling.selectedColorSecond.inner = null;
                } else {
                    filling.selectedColorSecond = {
                        outer: null,
                        inner: null,
                    };
                }
                return;
            }

            if (filling.selectedColor) {
                filling.selectedColor.inner = null;
            } else {
                filling.selectedColor = {
                    outer: null,
                    inner: null,
                };
            }
        }
    }

    clearLippingColor(conf: WindowActiveConfiguration, side?: ProfileColoredSides | ColoredSides) {
        if (side === 'inner') {
            conf.innerLippingColor = null;
            return;
        }

        conf.lippingColor = null;
    }

    clearMuntinColor(conf: WindowActiveConfiguration, side?: ProfileColoredSides | ColoredSides) {
        if (side === 'inner') {
            conf.MuntinsData.color = null;
            return;
        }

        conf.MuntinsData.colorOut = null;
    }

    resetWindowColors(conf: WindowActiveConfiguration) {
        for (const element of ['frame', 'sash']) {
            for (const side of ['outer', 'inner', 'core', 'alushell']) {
                this.clearWindowColor(
                    conf,
                    element as 'frame' | 'sash',
                    side as ProfileColoredSides
                );
            }
        }
        this.setDefaultColors(conf);
    }

    onlyDoubleSidedFrameColor(conf: WindowActiveConfiguration): boolean {
        const frameProfileIds = this.profilesService.getUsedFrameProfileIds(conf);
        return (
            frameProfileIds?.length > 0 &&
            frameProfileIds.every((profileId) => {
                const profileColorSides = this.profilesService.getProfilePriceLevelColorSides(
                    profileId
                );
                return (
                    profileColorSides &&
                    profileColorSides.length > 0 &&
                    profileColorSides.every((side) => side === 'double')
                );
            })
        );
    }

    isDoubleSidedColorAvailable(conf: WindowActiveConfiguration): boolean {
        const sashProfilesForSelectedSystem = this.getSashProfilesForSelectedSystem(conf);
        if (sashProfilesForSelectedSystem.length > 0) {
            return this.isDoubleSidedColorAvailableForSashProfile(sashProfilesForSelectedSystem);
        } else {
            return this.isDoubleSidedColorAvailableForVirtualSashProfile(conf);
        }
    }

    getSashProfilesForSelectedSystem(conf: WindowActiveConfiguration): Profile[] {
        const sashProfiles = this.profilesService.getProfilesByType('sash');
        if (sashProfiles.length > 0) {
            return sashProfiles.filter((p) => p.systems.includes(Number(conf.System.id)));
        } else {
            return [];
        }
    }

    isDoubleSidedColorAvailableForSashProfile(sashProfilesForSelectedSystem: Profile[]): boolean {
        return sashProfilesForSelectedSystem.every((sash) =>
            this.profilesService
                .getPriceLevel(Number(sash.priceLevelId))
                ?.price_levels.every((level) => level?.side === 'double')
        );
    }

    isDoubleSidedColorAvailableForVirtualSashProfile(conf: WindowActiveConfiguration) {
        const virtualDoorSash =
            conf.UsedProfiles && conf.UsedProfiles.find((p) => p.type === 'virtual_door_sash');
        if (
            virtualDoorSash &&
            virtualDoorSash.priceLevelId &&
            virtualDoorSash.systems.includes(Number(conf.System.id))
        ) {
            const priceLevel = this.profilesService
                .getPriceLevel(Number(virtualDoorSash.priceLevelId));
            return priceLevel?.price_levels.every((level) => level?.side === 'double') ?? false;
        }
        return false;
    }

    private hasDecoFillings(conf: WindowActiveConfiguration) {
        return conf.Sashes.some(
            (s) =>
                s.glazing?.type &&
                ['door_panels', 'deco_panels'].includes(s.glazing.type)
        );
    }

    private hasDecoPanelSecondColor(conf: WindowActiveConfiguration) {
        return conf.Sashes.some(
            (s) =>
                s.glazing?.type
                && ['door_panels', 'deco_panels'].includes(s.glazing.type)
                && s.glazing.available_second_color
        );
    }

    private getColoredSashFinder(sashType: string) {
        let filter: (sash: ActiveSash) => boolean = () => false;

        if (sashType === 'activeDoorSash') {
            filter = (sash: ActiveSash) =>
                sash.type?.type != null && ['DRA', 'DOA'].includes(sash.type.type);
        } else if (sashType === 'passiveDoorSash') {
            filter = (sash: ActiveSash) =>
                sash.type?.type != null && ['DRP', 'DOP'].includes(sash.type.type);
        } else {
            filter = (sash: ActiveSash) =>
                sash.type?.type != null && !['DRA', 'DOA', 'DRP', 'DOP'].includes(sash.type.type);
        }
        return filter;
    }

    /**
     * Zwraca tekstowy opis elementu konstrukcji.
     *
     * Do debugowania.
     *
     * @param colorElement Element z kolorem
     */
    private getElementString(colorElement?: ColorElement | null | undefined) {
        if (!colorElement) {
            return '';
        }
        return [
            colorElement?.element,
            typeof colorElement?.sash === 'string' ? colorElement?.sash : colorElement?.sash?.id,
            colorElement?.side,
            colorElement?.alt ? '1' : '0',
            colorElement?.intSash ? '1' : '0',
        ].join('/');
    }

    /**
     * Zwraca tekstowy opis koloru
     *
     * Do debugowania.
     *
     * @param colorElement Element z kolorem
     */
    private getColorString(color: IccColor | Partial<IccColor> | null | undefined) {
        if (!color) {
            return '';
        }
        return [
            color?.id,
            color?.name,
        ].join('/');
    }
}
