import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, _, isDefined, isObject } from '@icc/configurator/shared';
import {
    APP_CONFIG,
    AppConfigFactory,
    DrawService,
    ConfigurationsService,
    TranslateService,
    EventBusService,
    core,
} from '@icc/common';
import { ShutterFacade } from '../+state/shutter.facade';
import { RollerDimensionsService } from '@icc/legacy/configurator/steps/roller_shutter/dimensions.service';
import { Subscription } from 'rxjs';
import { ColorsService } from '@icc/legacy/configurator/steps/roller_shutter/colors.service';
import { Profile } from '@icc/window';
import { map } from 'rxjs/operators';
import { DrivesService } from '@icc/legacy/configurator/steps/roller_shutter/drives.service';

@Component({
    selector: 'icc-dimensions-options-page',
    templateUrl: './dimensions-options-page.component.html',
    styleUrls: ['./dimensions-options-page.component.scss'],
})
export class DimensionsOptionsPageComponent extends PageComponent implements OnInit {
    public title = _('INTERFACE|Zaawansowane');

    private subscriptions: Subscription[] = [];

    profiles: Profile[] = [];
    roundReels: any[] = [];
    boxSizes: any[] = [];
    profile$ = this.shutterFacade.profile$;
    roundReel: any = null;
    roundReel$ = this.shutterFacade.roundReel$.pipe(map(reel => reel.id));
    availableRoundReel = false;
    boxHeight$ = this.shutterFacade.boxHeight$;

    selectedConfiguratorType$ = this.shutterFacade.selectedConfiguratorType$;

    width$ = this.shutterFacade.width$;
    height$ = this.shutterFacade.fullHeight$;
    guidesEnable = this.config().IccConfig.Configurators.roller_shutter.guides || false;
    slatsEnable = this.config().IccConfig.Configurators.roller_shutter.slats || false;
    drawOptions: any = null;
    drawButtons = {
        alignments: true,
        dimensions: false,
        extensions: true,
        fullscreen: false,
        mullions: true,
        profiles: false,
        sashes: true,

        toggle: (
            type:
                | 'alignments'
                | 'dimensions'
                | 'extensions'
                | 'fullscreen'
                | 'mullions'
                | 'profiles'
                | 'sashes'
        ) => {
            if (type && typeof this.drawButtons[type] !== 'undefined') {
                this.drawButtons[type] = !this.drawButtons[type];
            }

            this.drawButtons.update();
        },

        update: (side: 'inner' | 'outer' = 'inner') => {
            const conf = this.configurationsService.conf.Current;
            const shutters = this.configurationsService.conf.Current.RollerShutter.shutters;
            if (conf) {
                this.drawOptions = Object.assign({}, this.drawService.options, {
                    base: {
                        side,
                    },
                    shutter: {
                        buttons: [
                            {
                                class: 'ico-minus common-icon',
                                condition: response =>
                                    response.shutter.commonRail && response.shutter.id,
                                onClick: response => this.toggleCommonRail(response.shutter.id),
                                positions: ['left'],
                                title: this.translateService.instant(
                                    'INTERFACE|Ustaw osobne prowadnice'
                                ),
                            },
                            {
                                class: 'ico-plus common-icon',
                                condition: response =>
                                    !response.shutter.commonRail && response.shutter.id,
                                onClick: response => this.toggleCommonRail(response.shutter.id),
                                positions: ['left'],
                                title: this.translateService.instant(
                                    'INTERFACE|Ustaw wspólną prowadnicę'
                                ),
                            },
                            {
                                class: 'ico-right guide-icon',
                                condition: response =>
                                    this.guidesEnable && response.shutter.id === shutters[0].id,
                                onClick: response =>
                                    this.guideSelection(response.shutter.id, 'left'),
                                positions: ['left'],
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz prowadnicę lewą'
                                ),
                            },
                            {
                                class: 'ico-left guide-icon',
                                condition: response =>
                                    this.guidesEnable
                                    && response.shutter.id === shutters[shutters.length - 1].id,
                                onClick: response =>
                                    this.guideSelection(response.shutter.id, 'right'),
                                positions: ['right'],
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz prowadnicę prawą'
                                ),
                            },
                            {
                                class: 'ico-both',
                                condition: response =>
                                    this.guidesEnable
                                    && response.shutter.commonRail
                                    && response.shutter.id,
                                onClick: response =>
                                    this.guideSelection(
                                        response.shutter.id,
                                        `common${response.shutter.id}`
                                    ),
                                positions: ['left'],
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz prowadnicę'
                                ),
                            },
                            {
                                class: 'ico-baseline',
                                condition: response =>
                                    this.guidesEnable
                                    && !response.shutter.commonRail
                                    && response.shutter.id,
                                positions: ['left'],
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz prowadnicę'
                                ),
                                buttons: [
                                    {
                                        class: 'ico-left baseline-icon',
                                        condition: response => this.guidesEnable,
                                        onClick: response =>
                                            this.guideSelection(
                                                response.shutter.id - 1,
                                                `middleLeft${response.shutter.id}`
                                            ),
                                        positions: ['left'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Wybierz prowadnicę prawą'
                                        ),
                                    },
                                    {
                                        class: 'ico-right baseline-icon',
                                        condition: response => this.guidesEnable,
                                        onClick: response =>
                                            this.guideSelection(
                                                response.shutter.id,
                                                `middleRight${response.shutter.id}`
                                            ),
                                        positions: ['left'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Wybierz prowadnicę lewą'
                                        ),
                                    },

                                ],
                            },
                            {
                                class: 'ico-border-bottom',
                                condition: response => this.slatsEnable,
                                onClick: response => this.slatSelection(response.shutter.id),
                                positions: ['bottom'],
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz listwę końcową'
                                ),
                            },
                        ],
                    },
                    indexes: {
                        multiplier: 3,
                    },
                    extendedRollerInWindow: this.config().IccConfig.Configurators
                        .extendedRollerInWindow,
                    onInterfaceClick: () => {},
                });
                this.drawOptions.base.side = 'inner';
            }
            this.rollerDimensionsService.updateDimensions(undefined, undefined, undefined, true);
        },
    };

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private shutterFacade: ShutterFacade,
        public drawService: DrawService,
        private rollerDimensionsService: RollerDimensionsService,
        private colorsService: ColorsService,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind'>,
        private translateService: TranslateService,
        private eventBusService: EventBusService,
        private drivesService: DrivesService,
    ) {
        super();
    }

    ngOnInit() {
        if (this.rollerDimensionsService.loadedData) {
            this.init();
            this.drawButtons.update();
        }
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }

    init() {
        this.profiles = this.rollerDimensionsService.getProfiles();
        this.roundReels = this.rollerDimensionsService.roundReels;
        this.boxSizes = this.rollerDimensionsService.boxSizes;
        this.boxSizes.sort((a,b)=>a-b);
        if (isObject(this.configurationsService.conf.Current.RollerShutter.roundReel)) {
            this.roundReel = this.configurationsService.conf.Current.RollerShutter.roundReel.id;
        } else {
            this.roundReel = isObject(this.rollerDimensionsService.roundReels[0])
                ? this.rollerDimensionsService.roundReels[0].id
                : null;
            this.rollerDimensionsService.changeRoundReel(this.roundReel);
        }
        this.availableRoundReel = this.config().IccConfig.Configurators.roller_shutter.roundReels && this.roundReels && this.roundReels.length > 1;
    }

    /**
     * Funkcja ustawia profil
     */
    changeProfile() {
        this.rollerDimensionsService.selectProfile();
    }

    /**
     * Funkcja ustawia rurę nawojową
     */
    changeRoundReel(roundReelId: number) {
        const roundReel = (this.roundReels || []).find(e => e.id === roundReelId);
        this.rollerDimensionsService.changeRoundReel(isDefined(roundReel) ? roundReel : null);
        this.drivesService.setDrive(this.configurationsService.conf.Current.RollerShutter.drive);
        this.rollerDimensionsService.updateDimensions();
    }

    onBoxHeightChange(boxHeight) {
        this.rollerDimensionsService.setBoxHeightChangedManually(true);
        this.rollerDimensionsService.onBoxHeightChange(boxHeight);
    }

    toggleCommonRail(shutterId) {
        const index = this.configurationsService.conf.Current.RollerShutter.shutters.findIndex(
            o => o.id === shutterId
        );
        const shutter = this.configurationsService.conf.Current.RollerShutter.shutters[index];

        if (shutter.commonRail) {
            shutter.commonRail = false;
        } else {
            shutter.commonRail = true;

            this.configurationsService.conf.Current.RollerShutter.shutters[
                index - 1
            ].realHeight = core.copy(shutter.realHeight);
        }

        this.rollerDimensionsService.setGuideProfiles();
        this.rollerDimensionsService.setSlatProfiles();
        this.rollerDimensionsService.updateDimensions(undefined, undefined, undefined, false);
    }

    /**
     * Funkcja otwierajaca dialog wyboru prowadnic
     * @param {object} shutterId
     */
    guideSelection(shutterId, side = null) {
        this.rollerDimensionsService.openModalGuideSelection(
            shutterId,
            side,
            this.configurationsService.conf.Current
        );
    }

    /**
     * Funkcja otwierajaca dialog wyboru prowadnic
     * @param {object} shutterId
     */
    slatSelection(shutterId) {
        this.rollerDimensionsService.openModalSlatSelection(
            shutterId,
            this.configurationsService.conf.Current
        );
    }
}
