<div id="icc-main-container" [class]="project + '-theme'">
    <mat-toolbar color="primary" id="main-toolbar" class="mat-elevation-z4" *ngIf="!isHandset && !openedPreview && !configuratorOptions?.embeddedInB2B">
        <span id="icc-logo">
            <img [src]="logo">
        </span>
        <span id="icc-version">
            {{ currentApplicationVersion }}
        </span>
        <div class="spacer"></div>

        <ng-container *ngFor="let option of additionalReturnButtons">
            <button [disabled]="!(savingButton$ | async)" mat-icon-button (click)="saveConfiguration(option)" [title]="RETURN_BUTTON_ENUM[option.returnButton]">
                <mat-icon [fontIcon]="option.materialIcon" matListIcon>
                    {{option.materialIcon || 'bubble_chart'}}
                </mat-icon>
            </button>
        </ng-container>
        <button (click)="backIconClick()" mat-icon-button>
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button (click)="homeIconClick($event)" mat-icon-button>
            <mat-icon>home</mat-icon>
        </button>

        <ng-container *ngIf="showBrowserLanguageSettings()">
            <button mat-icon-button [matMenuTriggerFor]="showCountryLanguages" style="width: auto; padding-right: 1%;">
                <mat-icon>flag</mat-icon>
                {{selectedLanguage}}
            </button>
            <mat-menu #showCountryLanguages="matMenu" xPosition="before">
                <ng-container *ngFor="let language of languages | keyvalue">
                    <button *ngIf="selectedLanguage !== language.value" mat-menu-item (click)="changeLanguage(language.key)">{{language.value}}</button>
                </ng-container>
            </mat-menu>
        </ng-container>

        <ng-container  *ngIf="showCurrenciesSelect()">
            <button mat-icon-button [matMenuTriggerFor]="showCurrenciesSelect" style="width: auto;">
                <mat-icon>monetization_on</mat-icon>
                {{selectedCurrency.currency}}
            </button>
            <mat-menu #showCurrenciesSelect="matMenu" xPosition="before">
                <ng-container *ngFor="let currency of currencies">
                    <button *ngIf="selectedCurrency.id !== currency.id" mat-menu-item (click)="changeCurrency(currency.id)">{{currency.currency}}</button>
                </ng-container>
            </mat-menu>
        </ng-container>
        <!-- Dodanie menu do zmiany waluty -->
    </mat-toolbar>
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="!(configuratorInitialized$ | async) || !(savingButton$ | async)" @progressBar></mat-progress-bar>
    <div class='icc-container'
        [class.full-sidenav]="!isDesktop && !isTablet && !isHandset"
        [class.small-sidenav]="isDesktop || isTablet"
        [class.mobile-sidenav]="isHandset"
        [class.visible-preview]="!isSmallDesktop && !isTablet && !isHandset"
        [class.hidden-preview]="isSmallDesktop || isTablet || isHandset || isFullscreen"
        [class.embedded-in-b2b]="configuratorOptions?.embeddedInB2B"
    >
        <div class="steps mat-elevation-z1">

            <mat-sidenav-container autosize>
                <mat-sidenav #snav class='sidenav' id="steps-sidenav" [mode]="isHandset ? 'over' : 'side'" [fixedInViewport]="isHandset" [disableClose]="!isHandset && (hasSteps$ | async)"
                    [opened]="!isHandset && (hasSteps$ | async)" class="mat-elevation-z3">
                    <mat-nav-list>
                        <mat-list-item *ngFor="let step of (steps$ | async); let i = index" (click)="switchStep(step.id)" [class.step-active]="currentStepId === i">
                            <mat-icon [fontSet]="step.stepIcon?.fontSet" [fontIcon]="step.stepIcon?.fontIcon"
                                matListIcon>{{step.stepIcon?.ligature}}
                            </mat-icon>
                            <span matLine translate [class.icc-ph]="translationLoading">{{step.stepName}}</span>
                        </mat-list-item>
                        <div *ngIf='isHandset'>
                            <mat-list-item *ngFor="let option of additionalReturnButtons" (click)="saveConfiguration(option)" mat-icon-button>
                                <mat-icon matListIcon>{{ option.materialIcon || 'bubble_chart' }}</mat-icon>
                                <span matLine translate [class.icc-ph]="translationLoading">{{ RETURN_BUTTON_ENUM[option.returnButton] }}</span>
                            </mat-list-item>
                        </div>
                        <mat-list-item *ngIf='isHandset' class='sidenav__home' (click)="homeIconClick($event)" mat-icon-button>
                                <mat-icon   matListIcon>home</mat-icon>
                                <span *ngIf='configuratorOptions && configuratorOptions.homeButtonText' matLine translate [class.icc-ph]="translationLoading">{{configuratorOptions.homeButtonText}}</span>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-sidenav>

                <mat-sidenav-content>
                    <mat-toolbar id="step-toolbar" class="mat-elevation-z2" [@search]="searching">
                        <button mat-icon-button (click)="snav.toggle()" *ngIf="isHandset && !pageTitle">
                            <mat-icon>menu</mat-icon>
                        </button>
                        <button mat-icon-button class="arrow-back" (click)="closePage()" *ngIf="pageTitle">
                            <mat-icon>arrow_back</mat-icon>
                        </button>
                        <div class="toolbarIcons" *ngIf="stepDescriptionEnabled">
                            <button mat-icon-button (click)="openInfoModal()">
                                <mat-icon>help_outline</mat-icon>
                            </button>
                        </div>
                        <h1 *ngIf="!pageTitle && !searching" [class.icc-ph-accent]="translationLoading"
                            [style.width.%]="((accessories?.categories?.length > 1 || accessories?.subcategories?.length > 1) && isFilterByCategoriesOptionSelected()) ? '0' : '100'"
                            >{{stepTitle | translate}}
                        </h1>
                        <h1 *ngIf="pageTitle && !searching" [class.icc-ph-accent]="translationLoading"
                            [style.width.%]="((accessories?.categories?.length > 1 || accessories?.subcategories?.length > 1) && isFilterByCategoriesOptionSelected()) ? '0' : '100'"
                            >{{pageTitle | translate}}
                        </h1>
                        <div class="wrapper">
                            <div class="filter"
                                *ngIf="!searching && (accessories?.categories?.length > 1 || accessories?.subcategories?.length > 1)">
                                <div class="filter__accessory-category" *ngIf="accessories?.categories?.length > 1">
                                    <mat-form-field appearance="none" class="filter__accessory-category-form">
                                        <mat-label>{{ 'Kategorie' | translate }}</mat-label>
                                        <mat-select [value]="selectedCategory.name">
                                            <mat-option class="filter__accessory-category-form__option" *ngFor="let category of accessories.categories" [value]="category.name"
                                                (click)="changeCategory(category)">
                                                {{category.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="filter__accessory-subcategory" *ngIf="showSubcategories()">
                                    <mat-form-field appearance="none" class="filter__accessory-subcategory-form">
                                        <mat-label> {{ "Podkategorie" | translate }}</mat-label>
                                        <mat-select [value]="selectedSubCategory.name">
                                            <mat-option class="filter__accessory-category-form__option" *ngFor="let subcategory of accessories.subcategories" [value]="subcategory.name"
                                                (click)="changeSubcategory(subcategory)">
                                                {{subcategory.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="isDefaultConfigurationAvailable()">
                                <button mat-button (click)="confirmSettingDefaultConfiguration()">
                                    {{ 'INTERFACE|Ustawienia domyślne' | translate }}
                                </button>
                            </div>
                            <div id="search" *ngIf="searching">
                                <mat-form-field class="search-input" floatLabel="never">
                                    <mat-label translate>INTERFACE|Wyszukaj</mat-label>
                                    <input matInput type="search" [formControl]="searchControl" #searchInput>
                                    <button matPrefix mat-button mat-icon-button>
                                        <mat-icon>search</mat-icon>
                                    </button>
                                    <button matSuffix mat-button mat-icon-button (click)="stopSearching()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                            <div class="spacer" *ngIf="!searching"></div>
                            <div class="toolbarIcons" *ngIf="!searching">
                                <ng-container *ngFor="let option of getPageToolbarOptions()">
                                    <button [ngClass]="option.hover === 'active' ? 'hover' : ''" mat-button (click)="menuItemHandler(option)" [title]="option.title | translate">
                                        <mat-icon [fontSet]="option.icon?.fontSet" [fontIcon]="option.icon?.fontIcon" matListIcon>
                                            {{option.icon?.ligature || 'bubble_chart'}}
                                        </mat-icon>
                                        <span translate *ngIf="!(accessories?.categories?.length > 1)">{{ option.title }}</span>
                                    </button>
                                </ng-container>
                                <button mat-icon-button [matMenuTriggerFor]="pageMenu" *ngIf="pageMenuOptions.length">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            </div>
                        </div>
                        </mat-toolbar>
                    <div #pageBanner id="banner-container" class="mat-elevation-z1" *ngIf="(openedMessage$ | async); let message"
                        @messageTrigger (@messageTrigger.done)="resizeDraw()">
                        <div id="banner-container-message" *ngIf="!message.messageSafe" [innerHtml]="message.message"></div>
                        <div id="banner-container-message" *ngIf="message.messageSafe" [innerHtml]="message.messageSafe"></div>
                        <div id="banner-container-actions">
                            <button mat-button color="primary" (click)="dismissMessage()">OK</button>
                        </div>
                    </div>
                    <div #pageContainer id="page-container"></div>
                    <ng-template [ngIf]="(isSmallDesktop || isTablet || isHandset) && !isSummaryHidden || forceReturnButton">
                        <ng-container [ngTemplateOutlet]="summary" [ngTemplateOutletContext]="{price: price}"></ng-container>
                    </ng-template>
                    <div id="stepper" class="mat-elevation-z3" *ngIf="(hasSteps$ | async) && !isStepperHidden && !pageTitle">
                        <span class="arrow-button">
                            <button mat-icon-button (click)="goToPreviousStep()">
                                <mat-icon>west</mat-icon>
                            </button>
                        </span>
                        <ng-template [ngIf]="isHandset">
                            <button mat-button class="stepper-step" *ngFor="let step of (steps$ | async);  let i = index" (click)="switchStep(step.id)"
                                [class.selected]="(selectedStep$ | async).id === step.id">
                                {{i + 1}}
                            </button>
                        </ng-template>
                        <span *ngIf="!isHandset" id="step-counter" [class.icc-ph-accent]="translationLoading">
                            {{(selectedStep$ | async)?.stepIndex + 1}}&nbsp;/&nbsp;{{(steps$ | async).length}}</span>
                        <span class="arrow-button">
                            <button *ngIf="!isHandset" mat-button (click)="goToNextStep()">
                                {{ 'INTERFACE|Dalej' | translate }}
                                <mat-icon>east</mat-icon>
                            </button>
                            <button *ngIf="isHandset" mat-icon-button (click)="goToNextStep()">
                                <mat-icon>east</mat-icon>
                            </button>
                        </span>
                    </div>
                </mat-sidenav-content>

                <mat-sidenav #sideFilters id="filters-sidenav" position="end" [mode]="'over'" [fixedInViewport]="isHandset"
                    class="mat-elevation-z3">
                    <div #filtersContainer id="filters-container"></div>
                </mat-sidenav>

            </mat-sidenav-container>
        </div>
        <div class="main" [class.opened]="openedPreview && (isSmallDesktop || isTablet || isHandset || isFullscreen)" >
            <mat-toolbar id="preview-toolbar" *ngIf="openedPreview && (isSmallDesktop || isTablet || isHandset || isFullscreen)">
                <button mat-icon-button (click)="closePreview()">
                    <mat-icon>west</mat-icon>
                </button>
            </mat-toolbar>
            <div id="main-container">
                <div class="preview" #preview [ngClass]="{'flip-symbols': flipSymbols}">
                    <icc-draw *ngIf='!this.openedConstructionPreview' [details]="this.drawService.details" [options]="drawOptions" iccDrawRefresh [parentElement]="preview" [fitTo]="((isSmallDesktop || isTablet || isHandset || isFullscreen) && openedPreview) ? 'width' : 'height'">
                    </icc-draw>
                    <icc-draw class='zoom_draw' *ngIf='this.openedConstructionPreview' [details]="this.drawService.details" [options]="drawOptions" fitTo="width" iccDrawResize [drawWidth]="width$ | async" [drawHeight]="height$ | async" [parentElement]="preview"></icc-draw>
                </div>
                <div class="preview-options">
                    <mat-button-toggle-group name="drawingSide" *ngIf='!this.openedConstructionPreview' [ngModel]="this.drawService.options.base.side" (ngModelChange)="switchDrawingSide($event)">
                        <mat-button-toggle value="inner">{{ 'CONFIGURATOR|podgląd wewnętrzny' | translate }}</mat-button-toggle>
                        <mat-button-toggle value="outer">{{ 'CONFIGURATOR|podgląd zewnętrzny' | translate }}</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="details" *ngIf="openedPreview && previewDetails && (isSmallDesktop || isTablet || isHandset || isFullscreen)">
                    <icc-details></icc-details>
                </div>
            </div>
            <ng-container [ngTemplateOutlet]="summary" [ngTemplateOutletContext]="{price: price, preview: (isSmallDesktop || isTablet  || isFullscreen) && openedPreview}"></ng-container>
            <div id="preview-action" class="mat-elevation-z3" *ngIf="openedPreview && isHandset && returnButton">
                <button mat-flat-button color="primary" [disabled]="!(savingButton$ | async)"
                    (click)="saveConfiguration()" [class.icc-ph-transparent]="translationLoading">
                    <ng-container [ngSwitch]="returnButton">
                        <ng-container *ngSwitchCase="'ADD'">{{ 'INTERFACE|Dodaj' | translate }}</ng-container>
                        <ng-container *ngSwitchCase="'SAVE'">{{ 'INTERFACE|Zapisz' | translate }}</ng-container>
                        <ng-container *ngSwitchCase="'OK'">{{ 'INTERFACE|OK' | translate }}</ng-container>
                        <ng-container *ngSwitchCase="'GENERATE_THUMBNAIL'">{{ 'CONFIGURATOR|Generuj miniaturę' | translate }}</ng-container>
                        <ng-container *ngSwitchCase="'ADD_TO_BASKET'">{{'INTERFACE|Dodaj do koszyka' | translate}}</ng-container>
                        <ng-container *ngSwitchCase="'ADD_TO_OFFER'">{{'INTERFACE|Dodaj do oferty' | translate}}</ng-container>
                        <ng-container *ngSwitchCase="'PRINT'">{{'INTERFACE|Generuj PDF' | translate}}</ng-container>
                    </ng-container>
                </button>
            </div>
        </div>
    </div>
</div>

<mat-menu #pageMenu="matMenu" xPosition="before">
    <ng-container *ngFor="let option of getPageMenuOptions()">
        <button mat-menu-item (click)="menuItemHandler(option)" translate *ngIf="!option.show || option.show()">
            <mat-icon *ngIf="option.icon?.ligature" [fontSet]="option.icon?.fontSet" [fontIcon]="option.icon?.fontIcon" matListIcon>
                {{option.icon?.ligature}}
            </mat-icon>
            <span translate>{{ option.title }}</span>
        </button>
    </ng-container>

    <mat-divider></mat-divider>
</mat-menu>

<ng-template #summary let-price="price" let-preview="preview">
    <div class="summary mat-elevation-z3" *ngIf='!this.openedConstructionPreview' [ngClass]="{'expand': expandSummary}">
        <button *ngIf="price.discount != 0 && price.showPrice" (click)="summaryToggle()" mat-icon-button>
            <mat-icon *ngIf="expandSummary">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="!expandSummary">keyboard_arrow_up</mat-icon>
        </button>
        <div class="summary-info" [ngClass]="{'extended': price.discount != 0}">
            <span id="price-nodiscount" *ngIf="!hiddenPrice
                            && (configuratorOptions.mode !== 'layoutThumbnail'
                            && (!price.price || price.price !== price.price)
                            && price.showPrice || (isBlockedAddToOffer$  | async))" [class.icc-ph-white]="translationLoading">
                {{ 'CONFIGURATOR|Nie można wycenić konstrukcji' | translate }}
                <mat-icon (click)='showConstructionIssuesModal()' class='show-bad-construction-info'
                    *ngIf="showConstructionIssuesModalButton">help</mat-icon>
            </span>
            <span id="price-before-discount" *ngIf="expandSummary && configuratorOptions.mode !== 'layoutThumbnail' && price.price && price.discount == 0 && price.promotion !== 0 && price.showPrice && !(isBlockedAddToOffer$  | async)" [class.icc-ph-white]="translationLoading">
                {{'OFFER|Cena bez promocji' | translate}}: <s *ngIf="price.listPriceBeforePromotions && price.listPriceBeforePromotions !== price.priceBeforePromotions">{{ price.listPriceBeforePromotions | currency: currency }}</s> {{ price.priceBeforePromotions | currency: currency  }}
            </span>
            <span id="price-discount" *ngIf="configuratorOptions.mode !== 'layoutThumbnail'&& price.price && price.discount == 0 && price.promotion !== 0 && price.showPrice && !(isBlockedAddToOffer$  | async)" [class.icc-ph-white]="translationLoading">
               {{ 'OFFER|Cena po promocji' | translate }}:  <span class="price"><s *ngIf="price.listPriceAfterDiscountsInCurrency && price.listPriceAfterDiscountsInCurrency !== price.priceAfterDiscountsInCurrency">{{ price.listPriceAfterDiscountsInCurrency | currency: currency : '-' : false }}</s> {{ price.priceAfterDiscountsInCurrency  | currency: currency : '-' : false }}</span>
            </span>
            <span id="price-before-discount" *ngIf="expandSummary && configuratorOptions.mode !== 'layoutThumbnail' && price.price && price.discount != 0 && price.promotion !== 0 && price.showPrice && !(isBlockedAddToOffer$  | async)" [class.icc-ph-white]="translationLoading">
                {{'OFFER|Cena bez promocji' | translate}}: <s *ngIf="price.listPriceBeforePromotions && price.listPriceBeforePromotions !== price.priceBeforePromotions">{{ price.listPriceBeforePromotions | currency: currency }}</s> {{ price.priceBeforePromotions | currency: currency  }}
            </span>
            <span id="price-discount" *ngIf="configuratorOptions.mode !== 'layoutThumbnail'&& price.price && price.discount != 0 && price.promotion !== 0 && price.showPrice && !(isBlockedAddToOffer$  | async)" [class.icc-ph-white]="translationLoading">
               {{ 'OFFER|Cena po promocji' | translate }}:  <span class="price"><s *ngIf="price.listPriceAfterDiscountsInCurrency && price.listPriceAfterDiscountsInCurrency !== price.priceAfterDiscountsInCurrency">{{ price.listPriceAfterDiscountsInCurrency | currency: currency : '-' : false }}</s> {{ price.priceAfterDiscountsInCurrency  | currency: currency : '-' : false }}</span><span *ngIf="quantity > 1 && showOfferDataInputs"> <ng-container *ngIf="!offerDiscountToPosition">
                ({{'OFFER|Rabat' | translate}}: {{ price.discount}}%)</ng-container>
            </span>
                <br>
                <span *ngIf="expandSummary && (quantity<=1 || !showOfferDataInputs)"><ng-container *ngIf="!offerDiscountToPosition">({{'OFFER|Rabat' | translate}}: {{ price.discount}}%)</ng-container></span>
            </span>
            <span id="price-before-discount" *ngIf="expandSummary && configuratorOptions.mode !== 'layoutThumbnail' && price.price && price.discount != 0 && price.promotion === 0 && price.showPrice && !(isBlockedAddToOffer$  | async)" [class.icc-ph-white]="translationLoading">
                {{'OFFER|Cena bez rabatu' | translate}}: <s *ngIf="price.listPrice && price.listPrice !== price.price">{{ price.listPrice | currency: currency }}</s> {{ price.price | currency: currency  }}
            </span>
            <span id="price-discount" *ngIf="configuratorOptions.mode !== 'layoutThumbnail'&& price.price && price.discount != 0 && price.promotion === 0 && price.showPrice && !(isBlockedAddToOffer$  | async)" [class.icc-ph-white]="translationLoading">
               {{ 'OFFER|Cena po rabacie' | translate }}:  <span class="price"><s *ngIf="price.listPriceAfterDiscountsInCurrency && price.listPriceAfterDiscountsInCurrency !== price.priceAfterDiscountsInCurrency">{{ price.listPriceAfterDiscountsInCurrency | currency: currency : '-' : false }}</s> {{ price.priceAfterDiscountsInCurrency  | currency: currency : '-' : false }}</span><span *ngIf="quantity > 1 && showOfferDataInputs"> <ng-container *ngIf="!offerDiscountToPosition">
                ({{'OFFER|Rabat' | translate}}: {{ price.discount}}%)</ng-container>
            </span>
                <br>
                <span *ngIf="expandSummary && (quantity<=1 || !showOfferDataInputs)"><ng-container *ngIf="!offerDiscountToPosition">
                    ({{'OFFER|Rabat' | translate}}: {{ price.discount}}%)</ng-container>
                </span>
            </span>
            <span id="price-nodiscount" *ngIf="configuratorOptions.mode !== 'layoutThumbnail' && price.price && price.discount === 0 && price.promotion === 0 && !price.hasGrossPrice && price.showPrice && !(isBlockedAddToOffer$  | async)" [class.icc-ph-white]="translationLoading">
                {{ 'OFFER|Cena netto' | translate }}: <span class="price"><s *ngIf="price.listPriceAfterDiscountsInCurrency && price.listPriceAfterDiscountsInCurrency !== price.priceAfterDiscountsInCurrency">{{ price.listPriceAfterDiscountsInCurrency | currency: currency: '-' : false }}</s> {{ price.priceAfterDiscountsInCurrency  | currency: currency : '-' : false }}</span>
            </span>
            <span id="price-nodiscount" *ngIf="configuratorOptions.mode !== 'layoutThumbnail' && price.price && price.discount === 0  && price.promotion === 0 && price.hasGrossPrice && price.showPrice && !(isBlockedAddToOffer$  | async)" [class.icc-ph-white]="translationLoading">
                {{ 'OFFER|Cena' | translate }}: <span class="price"><s *ngIf="price.listPriceAfterDiscountsInCurrency && price.listPriceAfterDiscountsInCurrency !== price.priceAfterDiscountsInCurrency">{{ price.listPriceAfterDiscountsInCurrency | currency: currency: '-' : false }}</s> {{ price.priceAfterDiscountsInCurrency  | currency: currency : '-' : false }}</span>
            </span>
            <span class='price-discount-unit-cost' *ngIf="quantity > 1 && showOfferDataInputs && pricePerItemAfterDiscounts">
                {{'OFFER|Cena za szt.' | translate}}: <s *ngIf="price.listPriceAfterDiscountsInCurrency && price.listPriceAfterDiscountsInCurrency !== price.priceAfterDiscountsInCurrency">{{ price.listPriceAfterDiscountsInCurrency | currency: currency: '-' : false }}</s> {{ (price$ | async).priceAfterDiscountsInCurrency  | currency: currency : '-' : false }}
            </span>
            <span class='price-discount-unit-cost' *ngIf="quantity > 1 && showOfferDataInputs && !pricePerItemAfterDiscounts">
                {{'OFFER|Cena za szt.' | translate}}: <s *ngIf="(price$ | async).listPrice && (price$ | async).listPrice !== (price$ | async).price">{{ (price$ | async).listPrice | currency: currency }}</s> {{ (price$ | async).price | currency: currency}}
            </span>
            <span id="price-info" *ngIf="configuratorOptions.mode !== 'layoutThumbnail' && price.price && price.hasGrossPrice && price.showPrice && !(isBlockedAddToOffer$  | async)" [class.icc-ph-white]="translationLoading">{{ 'OFFER|Wszystkie ceny zawierają VAT' | translate }}</span>
        </div>
        <div class="data-for-offer" *ngIf='showOfferDataInputs && !isHandset'>
            <p>{{ 'CONFIGURATOR|Ilość' | translate }}</p>
            <mat-form-field>
                <input matInput (blur)='onQuantityChange($event.target.value)'  [value]='quantity' type="number" min='1'>
            </mat-form-field>
        </div>
        <button mat-flat-button class="summary-button" color="primary" *ngIf="modalConfigurator"
            (click)="closeConfigurator()" [class.icc-ph-transparent]="translationLoading">
            {{ 'INTERFACE|Powrót' | translate }}
        </button>
        <button mat-flat-button class="summary-button" color="primary" *ngIf="(!isHandset && !isTablet && !isSmallDesktop && !isFullscreen || preview || forceReturnButton) && returnButton && !modalConfigurator" [disabled]="!(savingButton$ | async)"
            (click)="saveConfiguration()" [class.icc-ph-transparent]="translationLoading">
            {{ RETURN_BUTTON_ENUM[returnButton] | translate}}
        </button>
        <button mat-button class="summary-button-mobile" color="primary" *ngIf="(isSmallDesktop || isTablet || isHandset || isFullscreen) && !openedPreview && !forceReturnButton"
            (click)="openPreview()" [class.icc-ph-transparent]="translationLoading"
            >
            {{ 'INTERFACE|Zobacz podgląd' | translate }}
        </button>
    </div>
</ng-template>
